import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import renewal from "../../../assets/img/renewal.svg";
import planDuration from "../../../assets/img/paidon.svg";
import charges from "../../../assets/img/charges.svg";
import planName from "../../../assets/img/star.svg";
import details from "../../../assets/img/details.svg";
import paymentstatus from "../../../assets/img/paymentstatus.svg";
import hash from "../../../assets/img/hash.svg";
import exporttype from "../../../assets/img/exporttype.svg";
import { Button } from "react-bootstrap";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";

export const PlanDetail = ({ subscriptionDetails, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between ">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Plan Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={planName} />
                    <h5>Plan Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.planName}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={charges} />
                    <h5>Charges</h5>
                  </div>
                  <div className="description-col">
                    <h5>{subscriptionDetails && subscriptionDetails.price}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={planDuration} />
                    <h5>Plan Duration</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {" "}
                      {subscriptionDetails && subscriptionDetails.planDuration}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={renewal} />
                    <h5>Auto Renewal </h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.autoRenewal
                        ? "Yes"
                        : "No"}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={hash} />
                    <h5>Number of Staff</h5>
                  </div>
                  <div className="description-col">
                    <h5>{subscriptionDetails && subscriptionDetails.users}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={paymentstatus} />
                    <h5>Permission Type</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails &&
                        subscriptionDetails.permission_type}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={hash} />
                    <h5>Number of Batch</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.printCodes}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={exporttype} />
                    <h5>Export Type</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.export_type}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={renewal} />
                    <h5>Can have domain?</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.domainName
                        ? "Yes"
                        : "No"}
                    </h5>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="heading-col">
                    <img src={details} />
                    <h5>Details</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {subscriptionDetails && subscriptionDetails.description}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
