import React from "react";
import Skeleton from "react-skeleton-loader";

export const EnterpriceLoader = () => (
  <>
    <div style={{ padding: "14px 21px" }}>
      <form className="user_add m-0 addPlanform product_add">
        <div className="row">
          <div className="col-md-3">
            <Skeleton height={"14px"} width={"200px"} color="#e5e9ef" />
            <Skeleton height={"38px"} width={"100%"} color="#e5e9ef" />
          </div>
          <div className="col-md-3">
            <Skeleton height={"14px"} width={"200px"} color="#e5e9ef" />
            <Skeleton height={"38px"} width={"100%"} color="#e5e9ef" />
          </div>
          <div className="col-md-12" style={{ marginTop: "15px" }}>
            <Skeleton height={"14px"} width={"200px"} color="#e5e9ef" />
            <Skeleton height={"96px"} width={"100%"} color="#e5e9ef" />
          </div>

          <div
            className="col-md-12 footer-form pt-1"
            style={{ marginTop: "100px" }}
          >
            <Skeleton height={"40px"} width={"219px"} color="#e5e9ef" />
            <Skeleton height={"40px"} width={"219px"} color="#e5e9ef" />
          </div>
        </div>
      </form>
    </div>
  </>
);
