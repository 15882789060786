import React, { Component, useEffect, useState } from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import Modal from "react-bootstrap/Modal";
import userIcon from "../../../../src/assets/img/user-4.svg";
import scanid from "../../../../src/assets/img/scanid.svg";
import hashtag from "../../../../src/assets/img/hash.svg";
import star from "../../../../src/assets/img/star.svg";
import purchace_status from "../../../../src/assets/img/purchace_status.svg";

export const ScanRow = ({
  scanData,
  userColumn = true,
  batchViewHandler,
  userViewHandler,
}) => {
  const {
    scanId = "",
    batch = {},
    product = {},
    user = {},
    // user_name = '',
    // location,
    createdAt,
    updatedAt,
    loc,
    locs,
  } = scanData;
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { first_name = "", other_info = {} } = user || {};
  const [scans, setScans] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (locs.length > 0) {
      const a = [...locs];
      setScans(a.reverse());
    }
  }, [locs]);

  return (
    <>
      <tr>
        <td>{scanId}</td>
        <td>{scanData?.batchUnit?.codeId}</td>
        <td
          className={"cursor-pointer"}
          onClick={batchViewHandler ? batchViewHandler : null}
        >
          {batchNumber}
        </td>
        <td className="pname_scan">{productName}</td>
        {userColumn ? (
          <td
            className={"cursor-pointer"}
            // onClick={userViewHandler ? userViewHandler : null}
          >
            {first_name || "-"}{" "}
            <span className="scan_count" onClick={handleShow}>
              {loc ? "1 Scan" : `${locs.length} Scans`}
            </span>
          </td>
        ) : null}
        <td className="ploc_scan location_scan_td">
          {locs
            ? locs[locs.length - 1].address
            : other_info && other_info.address1
            ? other_info.address1
            : "-"}
        </td>
        <td
          className={"cursor-pointer"}
          // onClick={batchViewHandler ? batchViewHandler : null}
        >
          <p
            className="purchased_status yes"
            style={{
              color:
                scanData?.batchUnit?.isPurchased &&
                scanData?.batchUnit?.isPurchased == true
                  ? ""
                  : "red",
              backgroundColor:
                scanData?.batchUnit?.isPurchased &&
                scanData?.batchUnit?.isPurchased == true
                  ? ""
                  : "#ffe5e5",
            }}
          >
            {scanData?.batchUnit?.isPurchased &&
            scanData?.batchUnit?.isPurchased == true
              ? "Yes"
              : "No"}
          </p>
        </td>
        <td>
          {scans.length > 0
            ? [
                moment(scans[0].date).format(DEFAULT_TIME_FORMAT),
                moment(scans[0].date).format(DEFAULT_DATE_FORMAT),
              ].join(" Hrs | ")
            : updatedAt
            ? [
                moment(createdAt).format(DEFAULT_TIME_FORMAT),
                moment(createdAt).format(DEFAULT_DATE_FORMAT),
              ].join(" Hrs | ")
            : ""}
        </td>
      </tr>

      <Modal className="view_alert_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={userIcon} className="" alt="" />
                  User name
                </label>
                <span>
                  {user.first_name} {user.last_name}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Scan ID
                </label>
                <span>{scanId}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={hashtag} className="" alt="" />
                  Batch Number
                </label>
                <span>{batch.batchNumber}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Code ID
                </label>
                <span>{scanData?.batchUnit?.codeId}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={star} className="" alt="" />
                  Product Name
                </label>
                <span>{productName}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={purchace_status} className="" alt="" />
                  Purchase Status
                </label>
                <span className="">
                  {scanData?.batchUnit?.isPurchased &&
                  scanData?.batchUnit?.isPurchased == true
                    ? "Yes"
                    : "No"}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table_wrapper">
                <table>
                  <tr>
                    <th>Location</th>
                    <th>Scan Date & Time</th>
                  </tr>
                  {loc && (
                    <>
                      <tr>
                        <td>
                          {other_info && other_info.address1
                            ? other_info.address1
                            : "-"}
                        </td>
                        <td>
                          {createdAt
                            ? [
                                moment(createdAt).format(DEFAULT_TIME_FORMAT),
                                moment(createdAt).format(DEFAULT_DATE_FORMAT),
                              ].join(" Hrs | ")
                            : ""}
                        </td>
                      </tr>
                    </>
                  )}
                  {scans &&
                    scans.map((item) => {
                      return (
                        <>
                          <tr>
                            <td style={{ width: "70%" }}>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {item.address}
                              </div>
                            </td>
                            <td style={{ width: "30%" }}>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {item.date
                                  ? [
                                      moment(item.date).format(
                                        DEFAULT_TIME_FORMAT
                                      ),
                                      moment(item.date).format(
                                        DEFAULT_DATE_FORMAT
                                      ),
                                    ].join(" Hrs | ")
                                  : ""}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
