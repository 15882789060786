import React, { useEffect, useState } from "react";
import { TableLoader } from "../../../components/loader/Resusable/Table";
import noData from "../../../../assets/img/no-data.png";
import { ModuleListRow } from "./moduleListRow";
import { SubmitButton, InputTextField } from "../../../components/forms";
import { ModulesValidator } from "../../../../validators/modules";
import { ApiRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";

export const ModuleListComponent = ({
  moduleDetail,
  IndividualModuleDetail,
  isFilterApplied,
  showAddForm,
  onEdit,
  onDelete,
  isMainLoading,
  fetchModuleRecords,
  setFilter,
  sortBy,
  order,
  userDetail,
  viewUser,
  handleUpdateStatus,
  closeAddModal,
  handleAddCancel,
}) => {
  console.log(
    IndividualModuleDetail,
    IndividualModuleDetail["name"],
    "::data---"
  );

  const [name, setName] = useState(IndividualModuleDetail?.name || "");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isAdding, setIsAdding] = useState(false);

  useEffect(() => {
    setName(IndividualModuleDetail?.name || "");
  }, [IndividualModuleDetail]);

  const onChange = (event) => {
    setErrors({});
    setIsAdding(false);
    const {
      target: { name, value },
    } = event;
    if (name === "name") {
      setName(value.trimLeft());
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {
      name: name,
    };

    const { isValid, errors } = ModulesValidator(payload);

    let filterData = "";

    if (isValid) {
      setErrors({});
      setIsAdding(true);

      if (filterData.length === 0) {
        if (IndividualModuleDetail._id) {
          payload = { ...payload, _id: IndividualModuleDetail._id };
          updateEntry(payload);
        } else {
          addEntry(payload);
        }
      } else {
        setIsLoading(false);
        setIsAdding(false);
      }
    } else {
      setIsLoading(false);
      setErrors(errors);
      return;
    }
  };

  const addEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_MODULE_NEW.service,
      ApiRoutes.ADD_MODULE_NEW.url,
      ApiRoutes.ADD_MODULE_NEW.method,
      ApiRoutes.ADD_MODULE_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      setIsAdding(false);
      if (response.code !== 422) {
        toast.error(response.messages[0]);
      } else {
        setErrors(response.validationErrors);
      }
    } else {
      toast.success(response.data.message);
      setIsLoading(false);
      setIsAdding(false);
      setName("");
      handleAddCancel();
    }
  };

  const updateEntry = async (payload) => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.UPDATE_MODULE_NEW.service,
      ApiRoutes.UPDATE_MODULE_NEW.url,
      ApiRoutes.UPDATE_MODULE_NEW.method,
      ApiRoutes.UPDATE_MODULE_NEW.authenticate,
      undefined,
      payload
    );

    if (response && response.isError) {
      setIsLoading(false);
      setIsAdding(false);
      toast.error(response.messages[0]);
    } else {
      toast.success(response.data.message);
      setIsLoading(false);
      setIsAdding(false);
      setName("");
      handleAddCancel();
    }
  };

  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading || isMainLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <>
            {userDetail === "Web" && (
              <table width="100%" cellSpacing="0">
                <thead className="pad-top">
                  <tr>
                    <th style={{ width: "100%" }}>
                      Module Name{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === "name"
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter("name", "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>{" "}
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {showAddForm && (
                    <tr className="form-row-table lw-space add-table-row">
                      <td
                        className={` ${
                          errors && (errors?.name || errors?.name)
                            ? "has-error"
                            : ""
                        }`}
                      >
                        <InputTextField
                          id="name"
                          className={"form-control-user"}
                          name="name"
                          value={name}
                          onChange={(e) => onChange(e)}
                          errorMessage={
                            errors && errors.name ? errors.name : ""
                          }
                          maxLength={30}
                          placeholder={"Enter Module Name"}
                        />
                        <br />
                      </td>

                      <td style={{ padding: "8px 15px 8px 12px" }}>
                        <button
                          className={`btn btn-secondary can-btn-table ${
                            errors && errors?.name ? "btn-org" : ""
                          }`}
                          onClick={() => {
                            setName("");
                            closeAddModal();
                          }}
                        >
                          {" "}
                          Cancel{" "}
                        </button>
                        <SubmitButton
                          className={`save-btn-table add ${
                            errors && errors?.name ? "btn-org" : ""
                          }`}
                          text={IndividualModuleDetail?.name ? "Edit" : "Add"}
                          isLoading={isAdding}
                          onSubmit={handleSubmit}
                        />
                        <br />
                      </td>
                    </tr>
                  )}

                  {moduleDetail && moduleDetail.length ? (
                    moduleDetail.map((IndividualModuleDetail, index) => (
                      <ModuleListRow
                        key={index}
                        IndividualModuleDetail={IndividualModuleDetail}
                        fetchModuleRecords={fetchModuleRecords}
                        onEdit={() => onEdit(IndividualModuleDetail)}
                        onDelete={() => onDelete(IndividualModuleDetail._id)}
                        viewUser={() => viewUser(IndividualModuleDetail)}
                        handleUpdateStatus={(status, val) =>
                          handleUpdateStatus(status, val)
                        }
                      />
                    ))
                  ) : (
                    <tr className="no-tr">
                      <td
                        colSpan={5}
                        className={"text-center image-no-data-xs"}
                      >
                        {isFilterApplied ? (
                          <img src={noData} alt="" />
                        ) : (
                          <img src={noData} alt="" />
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            )}
          </>
        )}
      </div>
    </div>
  );
};
