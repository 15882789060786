/** @format */

import React, { Component } from "react";
import * as qs from "query-string";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ApiRoutes, AppRoutes } from "../../../config";
import { ApiHelper } from "../../../helpers";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { DeleteErrorModal } from "../../components/sweetAlertComponent/DeleteError";
import Pagination from "../../components/pagination";
import { ListComponent } from "../../components/batch";
import { FilterComponent } from "../../components/filters";
import { SearchFilter } from "../../components/filters/searchFilter";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import PrintBatchModal from "./PrintBatch";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";
import pageDropdownIcon from "../../../assets/img/tag-bd.svg";
import AsyncSelect from "react-select/async";
import "./batches.css";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

class ManageBatches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batches: [],
      clientList: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      activeBatch: null,
      itemCount: "", // Item count admin wants to print
      printBatchError: "",
      sortBy: "newestFirst",
      order: "asc",
      search: "",
      clientName: { label: "PHL", value: "PHL" },
      isFilterApplied: false,
      printModal: false,
      deleteBatchError: false,
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }
  componentDidMount = () => {
    this.handleQueryParams();
  };
  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;
    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        sortBy: query.sort || "newestFirst",
        order: query.order ? query.order : "asc",
        search: query.search || "",
        isFilterApplied: query.sort || query.search ? true : false,
        clientName: query.clientName || this.state.clientName,
      },
      () => this.fetchBatches()
    );
    this.fetchClientList();
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  fetchBatches = async () => {
    const { currentPage, limit, search, sortBy, order } = this.state;
    let clientName = this.state.clientName.value;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      search,
      sortBy,
      order,
      clientName,
      // :
      //   sortBy === 'createdAt' || sortBy === 'availableUnits' ? 'desc' : 'asc',
    };
    this.setState({
      isLoading: true,
    });
    if (search) {
      new ApiHelper().cancelRequest("cancel");
    }
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.BATCHES_LIST.service,
      ApiRoutes.BATCHES_LIST.url,
      ApiRoutes.BATCHES_LIST.method,
      ApiRoutes.BATCHES_LIST.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const {
        data: { batches, totalRecords },
      } = response.data;
      this.setState({
        batches,
        totalRecords,
      });
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_CLIENT_OPTION_LIST.service,
      ApiRoutes.GET_CLIENT_OPTION_LIST.url,
      ApiRoutes.GET_CLIENT_OPTION_LIST.method,
      ApiRoutes.GET_CLIENT_OPTION_LIST.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
    } else {
      let { data } = response.data;
      data = response.data.data.filter((item) => {
        return item.label.trim() !== "";
      });
      this.setState({
        clientList: [
          {
            label: "All",
            value: "all",
          },
          {
            label: "PHL",
            value: "PHL",
          },
          ...data,
        ],
      });
    }
  };

  onSelect = (options, name) => {
    this.setState({
      [name]: options,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (options) {
      params["clientId"] = options.value;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  loadClientOptions = async (inputValue, callback) => {
    //new ApiHelper().cancelRequest("cancel");
    //const result = await this.fetchProductsOption(inputValue);
    const result = this.state.clientList.filter((p) =>
      p.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    callback(result);
  };

  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };
  setFilter = (sortBy, order, name) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params[name] = sortBy;
    }
    if (order) {
      params.order = order;
    }
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  // to manage item count change
  handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value, printBatchError: "" });
  };
  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    console.log("onSearch", this.state.search);
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };
  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };
  onDelete = async (id, batchData) => {
    // If batch is already assigned to some clients
    if (batchData.itemCount !== batchData.availableUnits) {
      this.setState({
        deleteBatchError: true,
      });
    } else {
      const { value } = await SweetAlertComponent({
        text: `Are you sure, you want to delete the batch ${batchData.batchNumber} ?`,
      });
      if (!value) {
        return;
      }
      this.setState({
        isLoading: true,
      });
      const response = await new ApiHelper().FetchFromServer(
        ApiRoutes.DELETE_BATCH.service,
        ApiRoutes.DELETE_BATCH.url.replace(":id", id),
        ApiRoutes.DELETE_BATCH.method,
        ApiRoutes.DELETE_BATCH.authenticate,
        undefined,
        undefined
      );
      this.setState({
        id: "",
      });
      if (response && response.isError) {
        toast.error(response.messages[0]);
      } else {
        await this.fetchBatches();
        toast.success(response.messages[0]);
      }
    }
  };
  viewHandler = (id) => {
    const {
      props: {
        location: { search },
      },
    } = this;
    this.props.history.push({
      pathname: AppRoutes.VIEW_BATCH.replace(":id", id),
      state: {
        search,
      },
    });
  };
  onPrint = (id) => {
    const { batches } = this.state;
    this.setState({
      printModal: true,
      activeBatch: batches.filter((batch) => batch._id === id)[0] || {},
    });
  };
  // To close print batch modal
  handleClose = () => {
    this.setState({
      printModal: false,
      activeBatch: "",
      itemCount: "",
    });
  };
  // To close delete batch error modal
  handleCloseDelete = () => {
    this.setState({
      deleteBatchError: false,
    });
  };
  openPrintModal = () => {
    const { printModal, activeBatch, printBatchError, itemCount } = this.state;
    return (
      <PrintBatchModal
        open={printModal}
        batchData={{
          ...activeBatch,
          productName:
            activeBatch && activeBatch.product && activeBatch.product.length
              ? activeBatch.product[0].productName
              : "",
          productName:
            activeBatch && activeBatch.product
              ? activeBatch.product.productName
              : "",
        }}
        itemCount={itemCount}
        printBatchError={printBatchError}
        onChange={this.handleChange}
        handleClose={this.handleClose}
        fetchData={this.fetchBatches}
      />
    );
  };
  render() {
    const {
      clientList,
      batches,
      isFilterApplied,
      isLoading,
      totalRecords,
      currentPage,
      limit,
      order,
      sortBy,
      search,
      deleteBatchError,
      clientName,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filtersOption = [
      {
        label: "Batch Number",
        value: "batchNumber",
      },
      {
        label: "Product Number",
        value: "productNumber",
      },
      {
        label: "Product Name",
        value: "productName",
      },
      {
        label: "Available Units",
        value: "availableUnits",
      },
      {
        label: "Created Date",
        value: "createdAt",
      },
      {
        label: "Oldest First",
        value: "oldestFirst",
      },
      {
        label: "Newest First",
        value: "newestFirst",
      },
    ];

    const pagesOption = [
      {
        label: "10 Per page",
        value: 10,
      },
      {
        label: "20 Per page",
        value: 20,
      },
      {
        label: "50 Per page",
        value: 50,
      },
    ];

    return (
      <div className="container-fluid fluid-pd-2">
        <h1 className="h3-head-mob d-md-none">Batches</h1>

        <Breadcrumbs pathname={pathname} />
        {/* <p className='breadcrums-top'>
        Batch Listing
        </p>  */}

        <div className="card card-pagination card-main">
          <div className="card-header  py-7550  align-items-center justify-content-between">
            <div className="row">
              <div className="col-md-3 ">
                <h6 className="font-book ">Batch Listing Details</h6>
              </div>

              <div className="col-md-10  mob-filter-data">
                <Dropdown className="no-arrow dp-right">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn-mob"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={pageDropdownIcon} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow dropdown-menu-right dropdown-opt-mob mob-drp">
                    {filtersOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.setFilter(value, "asc", "")}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="dropdown no-arrow dp-right">
                  <button
                    className="btn btn-secondary filter-btn-mob"
                    id="statusMenuLink-mob"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img src="img/tag-search-bd.svg" alt="" />
                  </button>
                </div>
              </div>

              <div className="col-md-9">
                <div className="custom_header_filters">
                  <SearchFilter
                    placeholder={"Search by Batch No., Product No. and Name…"}
                    search={search}
                    onChange={this.handleSearch}
                  />

                  {/*<FilterComponent
                  label={"Client"}
                  options={clientList}
                  selectedFilter={clientName}
                  onFilterChange={(selectedValue) =>
                    this.setFilter(selectedValue, "", "clientName")
                  }
                />*/}
                  <div
                    className=""
                    style={{
                      zIndex: "1",
                      maxWidth: "170px",
                      minWidth: "170px",
                      marginRight: "8px",
                    }}
                  >
                    <AsyncSelect
                      loadOptions={this.loadClientOptions}
                      placeholder={"Select Client"}
                      maxMenuHeight={200}
                      styles={customStyles}
                      defaultOptions={clientList}
                      value={clientName}
                      onChange={(selectedOption) =>
                        this.onSelect(selectedOption, "clientName")
                      }
                      noOptionsMessage={() => "No Option"}
                    />
                  </div>
                  <FilterComponent
                    label={"Sort By"}
                    options={filtersOption}
                    selectedFilter={sortBy}
                    onFilterChange={(selectedValue) =>
                      this.setFilter(selectedValue, "", "sort")
                    }
                  />
                  <Link
                    to={AppRoutes.ADD_BATCH}
                    className="btn btn-primary add-btn add-wd-1024"
                  >
                    {" "}
                    Add Batch
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ListComponent
            batches={batches}
            isFilterApplied={isFilterApplied}
            isLoading={isLoading}
            order={order}
            sortBy={sortBy}
            setFilter={this.setFilter}
            onDelete={this.onDelete}
            onPrint={this.onPrint}
            onDeleteModal={this.onDeleteModal}
            onView={this.viewHandler}
          />
        </div>
        <div className="footer-pg custom-align">
          {!isLoading ? (
            <>
              <div className="float-left mt-2">
                <p className="page-desc-left">
                  Total: <span className="bold-black">{totalRecords}</span>
                </p>
              </div>
              <div className="float-right mt-2">
                <p className="page-desc ml-2 float-right">Table View</p>

                <Dropdown className="no-arrow dp-right ml-2">
                  <Dropdown.Toggle
                    variant="secondary"
                    id="dropdown-basic"
                    className={"filter-btn"}
                  >
                    {" "}
                    <span className="staff-value">
                      {
                        (
                          pagesOption.filter(
                            (filter) => filter.value === limit
                          )[0] || {}
                        ).label
                      }
                    </span>{" "}
                    <img src={filterarrowIcon} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="shadow top-open dropdown-menu-right dropdown-staff">
                    {pagesOption.map(({ label, value }, index) => (
                      <Dropdown.Item
                        as="div"
                        key={index}
                        onClick={() => this.onLimitChange(value)}
                      >
                        {label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {totalRecords > limit ? (
                  <div className="float-right">
                    <Pagination
                      totalRecords={totalRecords}
                      currentPage={currentPage}
                      pageLimit={limit}
                      onPageChanged={this.onPageChanged}
                    />
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
        {this.openPrintModal()}
        <DeleteErrorModal
          open={deleteBatchError}
          handleClose={this.handleCloseDelete}
          message={"This batch has been assigned to some client."}
          moduleName={"Batch"}
        />
      </div>
    );
  }
}

export default ManageBatches;
