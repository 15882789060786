import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs.jsx";
import debounce from "lodash.debounce";
import * as qs from "query-string";
import { ApiRoutes, AppRoutes } from "../../../config/index.js";
import { ApiHelper } from "../../../helpers/index.js";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../components/sweetAlertComponent/index.js";
import "./index.css";
import { InputTextField } from "../../components/forms/InputTextField.js";
import { SubmitButton } from "../../components/forms/SubmitButton.js";
import { EnterpriceLoader } from "../../components/loader/Resusable/EnterpriceLoader";
import { addContactPlan } from "../../../validators/addContactPlan";
class PlanContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      search: "",
      order: "asc",
      planData: [],
      isEditMode: false,
      has_plan: false,
      name: "",
      email: "",
      planId: "",
      description: "",
    };
    this.emitSearch = debounce(this.onSearch, 200);
  }

  componentDidMount = () => {
    this.handleQueryParams();
  };

  handleQueryParams = () => {
    const {
      location: { search },
    } = this.props;

    const query = qs.parse(search);
    this.setState(
      {
        currentPage: query.page ? parseInt(query.page) : 1,
        limit: query.limit ? parseInt(query.limit) : 10,
        search: query.search || "",
        sortBy: query.sortBy || "all",
        order: query.order || "asc",
      },
      () => this.getPlans()
    );
  };

  componentDidUpdate = ({ location: { search: prevSearch } }) => {
    const {
      location: { search },
    } = this.props;
    if (prevSearch !== search) {
      this.handleQueryParams();
    }
  };

  getPlans = async () => {
    const { currentPage, limit, sortBy, search, order } = this.state;
    let skip = limit * (currentPage - 1);
    let data = {
      skip,
      limit,
      sortBy,
      search,
      order,
      planType: "Contact",
    };
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.CHOOSE_SUBSCRIPTION.service,
      ApiRoutes.CHOOSE_SUBSCRIPTION.url,
      ApiRoutes.CHOOSE_SUBSCRIPTION.method,
      ApiRoutes.CHOOSE_SUBSCRIPTION.authenticate,
      data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      if (response.data.data && response.data.data.length > 0) {
        this.setState({
          planId: response.data.data[0].id,
          name: response.data.data[0].name,
          email: response.data.data[0].email,
          description: response.data.data[0].description,
          has_plan: response.data.data.length > 0,
        });
        this.setState({
          totalRecords: response.data.count,
        });
      }
    }
  };

  onDelete = async (id) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this plan ?`,
    });
    if (!value) {
      return;
    }
    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SUBSCRIPTION.service,
      ApiRoutes.DELETE_SUBSCRIPTION.url.replace(":id", id),
      ApiRoutes.DELETE_SUBSCRIPTION.method,
      ApiRoutes.DELETE_SUBSCRIPTION.authenticate,
      undefined
    );
    this.setState({
      isLoading: false,
    });

    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      this.getPlans();
    }
  };
  onPageChanged = (page) => {
    const { currentPage } = this.state;
    if (page !== currentPage) {
      const {
        location: { search, pathname },
      } = this.props;
      const query = qs.parse(search);
      this.props.history.push(
        [pathname, qs.stringify({ ...query, page })].join("?")
      );
    }
  };

  onLimitChange = (limit) => {
    const {
      location: { search, pathname },
    } = this.props;
    const query = qs.parse(search);
    this.props.history.push(
      [pathname, qs.stringify({ ...query, page: 1, limit })].join("?")
    );
  };

  // To handle search on typing
  handleSearch = (event) => {
    const {
      target: { name, value },
    } = event;
    this.setState({ [name]: value });
    event.persist();
    this.emitSearch();
  };

  onSearch = () => {
    const { search } = this.state;
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    params.search = search;
    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  setFilter = (sortBy, order) => {
    const {
      props: {
        location: { search: searchParam, pathname },
      },
    } = this;
    const query = qs.parse(searchParam);
    let params = {};
    params.page = 1;
    if (sortBy) {
      params.sortBy = sortBy;
    }

    if (order) {
      params.order = order;
    }

    this.props.history.push(
      [pathname, qs.stringify({ ...query, ...params })].join("?")
    );
  };

  onEdit = async (planData) => {
    if (planData?.counts > 0) {
      toast.error("Plan is used by clients");
    } else {
      this.setState({
        isEditMode: true,
        planData: planData,
      });
      this.props.history.push(AppRoutes.Edit_plan.replace(":id", planData.id));
    }
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const {
      planType,
      // planDuration,
      name,
      description,
      price,
      users,
      printCodes,
      domainName,
      email,
      planId,
      clientId,
      remark,
      permissionType,
      exportType,
      tags,
      priority,
    } = this.state;
    let payload = {};
    payload = {
      planType: "Contact",
      name: name || "",
      email: email || "",
      priority: "10",
      description: description || "",
    };
    let finalData = payload;
    const { isValid, errors } = addContactPlan(finalData);
    if (isValid) {
      finalData.domainName = finalData.domainName === 1 ? true : false;
      if (planId) {
        this.updatePlan(finalData);
      } else {
        this.addPlan(finalData);
      }
    } else {
      this.setState({ errors: errors });
      return;
    }
  };

  handleChange = (event) => {
    var regex = /^\d+\.\d{0,2}$/;
    const {
      target: { type, value, name, checked },
    } = event;

    if (name === "price" && isNaN(value)) {
      return;
    }
    if (
      (name === "users" && isNaN(value)) ||
      (name === "users" && regex.test(value))
    ) {
      return;
    }
    if (
      (name === "printCodes" && isNaN(value)) ||
      (name === "printCodes" && regex.test(value))
    ) {
      return;
    }
    this.setState({
      [name]: type === "checkbox" ? checked : value.trimLeft(),
      // errors: { ...planData.errors, [name]: "" },
    });
  };

  updatePlan = async (payload) => {
    const { planId } = this.state;

    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EDIT_SUBSCRIPTION.service,
      ApiRoutes.EDIT_SUBSCRIPTION.url.replace(":id", planId),
      ApiRoutes.EDIT_SUBSCRIPTION.method,
      ApiRoutes.EDIT_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.PLANS_CONTACT);
    }
  };

  addPlan = async (payload) => {
    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.ADD_SUBSCRIPTION.service,
      ApiRoutes.ADD_SUBSCRIPTION.url,
      ApiRoutes.ADD_SUBSCRIPTION.method,
      ApiRoutes.ADD_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.PLANS_CONTACT);
    }
  };

  render() {
    const {
      // activeId,
      isLoading,
      has_plan,
      errors,
      isAdding,
    } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    const filterOption = [
      { label: "ALL", value: "all" },
      { label: "Plan Name", value: "name" },
      { label: "Description", value: "description" },
      { label: "Company Name", value: "companyName" },
      { label: "Duration", value: "duration" },
      { label: "Price", value: "price" },
    ];
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">Plan Listing</h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-6 head-cat-col-lg">
                  {/* <h6 className="font-book">Plan Listing</h6> */}
                  <div className="d-flex align-items-center scan_rules_filter ml-0">
                    <Link
                      to={AppRoutes.PLANS_PAID}
                      style={{ minWidth: "90px" }}
                      className="button "
                    >
                      {" "}
                      Paid
                    </Link>
                    <Link
                      to={AppRoutes.PLANS_CUSTOM}
                      style={{ minWidth: "90px" }}
                      className="button"
                    >
                      {" "}
                      Custom
                    </Link>
                    <Link
                      to={AppRoutes.PLANS_CONTACT}
                      style={{ minWidth: "90px" }}
                      className="button active"
                    >
                      {" "}
                      Contact
                    </Link>
                  </div>
                </div>
                {/* <div className="col-md-9 action-col-lg ">
                  <div className="dp-right d-flex align-items-center customWidthSearchbar">
                    <SearchFilter
                      id={"sm-search-filter"}
                      placeholder={
                        "Search by Client Name, Plan Name and Description"
                      }
                      search={search}
                      onChange={this.handleSearch}
                    />
                    <FilterComponent
                      options={filterOption}
                      selectedFilter={sortBy}
                      onFilterChange={this.setFilter}
                    />
                    <Link
                      to={AppRoutes.ADDPLAN}
                      className="btn btn-primary add-btn mr-0"
                    >
                      Add Plan
                    </Link>
                  </div>
                </div> */}
              </div>
            </div>

            {isLoading ? (
              <>
                <EnterpriceLoader />
              </>
            ) : (
              <>
                <div style={{ padding: "14px 21px" }}>
                  <form
                    className="user_add m-0 addPlanform product_add"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="row">
                      <div className="col-md-3">
                        <InputTextField
                          id="email"
                          label={"Plan Name"}
                          className={"form-control-user"}
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          maxLength={30}
                          errorMessage={
                            errors && errors.name ? errors.name : ""
                          }
                          placeholder={"Enter Plan Name"}
                        />
                      </div>
                      <div className="col-md-3">
                        <InputTextField
                          id="email"
                          label={"Email"}
                          className={"form-control-user"}
                          name="email"
                          value={this.state.email}
                          onChange={this.handleChange}
                          errorMessage={
                            errors && errors.email ? errors.email : ""
                          }
                          maxLength={30}
                          placeholder={"Enter email"}
                        />
                      </div>

                      <div className="col-md-12" style={{ marginTop: "15px" }}>
                        <label htmlFor="brief" className="form-control-label">
                          Brief
                        </label>
                        <textarea
                          className="form-control form-control-textarea wt-resize"
                          cols="50"
                          id="brief"
                          aria-describedby="category_nameHelp"
                          rows="3"
                          name={"description"}
                          value={this.state.description}
                          onChange={this.handleChange}
                          maxLength={150}
                          style={{
                            minHeight: "96px",
                            maxHeight: "96px",
                            padding: "9px 0 0 0",
                          }}
                          placeholder={
                            "Enter brief of plan, upto 150 characters"
                          }
                        />
                        <div className={"text-danger"}>
                          {errors && errors.description
                            ? errors.description
                            : ""}
                        </div>
                      </div>

                      <div
                        className="col-md-12 footer-form pt-1"
                        style={{ marginTop: "100px" }}
                      >
                        <Link
                          to={AppRoutes.PLANS_PAID}
                          className="btn btn-primary cancel-btn"
                          //   onClick={handleCancel}
                        >
                          CANCEL
                        </Link>
                        {/* <button className="btn btn-primary submit-btn"> ADD</button> */}

                        <SubmitButton
                          className={"submit-btn"}
                          text={has_plan ? "UPDATE" : "ADD"}
                          isLoading={isAdding}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default PlanContact;
