import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { AppConfig } from "../../../config";
import prevIcon from "../../../assets/img/slider-left.svg";
import nextIcon from "../../../assets/img/slider-right.svg";
import "./image-view.css";

export const ImageView = ({ images, open, handleClose }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const handlePrevious = () => {
    setActiveIndex((prevIndex) => prevIndex - 1);
  };
  const handleNext = () => {
    setActiveIndex((prevIndex) => prevIndex + 1);
  };
  return (
    <Modal
      show={open}
      onHide={handleClose}
      dialogClassName="modal-40w"
      restoreFocus={false}
      className={"product-image-gallery"}
    >
      <Modal.Body className="modal-full-body-counterfeit">
        <button type="button" className="close" onClick={handleClose}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>
        <div className={"text-center"}>
          {activeIndex > 0 ? (
            <span
              className={"cursor-pointer left-ar-counterfeit"}
              onClick={handlePrevious}
            >
              <img src={prevIcon} alt="prev" />
            </span>
          ) : null}
          <img
            src={
              images[activeIndex] && images[activeIndex].startsWith("https://")
                ? images[activeIndex]
                : `${AppConfig.FILES_ENDPOINT}${images[activeIndex]}`
            }
            alt=""
            className="image-modal-view-counterfeit"
          />
          {activeIndex < images.length - 1 ? (
            <span
              className={"cursor-pointer right-ar-counterfeit"}
              onClick={handleNext}
            >
              <img src={nextIcon} alt="next" />
            </span>
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
};
