// import { lazy } from 'react';
import { AppRoutes } from "../config";
import { DashboardLoader } from "../view/components/loader/Dashboard";
import { OriginLoader } from "../view/components/loader/Origin";
import { ProductLoader } from "../view/components/loader/Product";
import { CounterfeitLoader } from "../view/components/loader/Counterfeit";
import { ScanLoader } from "../view/components/loader/Scan";
import { UsersLoader } from "../view/components/loader/Users";
import { BatchesLoader } from "../view/components/loader/Batches";
import { CategoryLoader } from "../view/components/loader/Category";
import { VideoLoader } from "../view/components/loader/Video";
import { AddUserLoader } from "../view/components/loader/Users/AddUsers";
import { ViewUserLoader } from "../view/components/loader/Users/ViewUser";
import { ProductAddLoader } from "../view/components/loader/Product/ProductAdd";
import { BatchAddLoader } from "../view/components/loader/Batches/BatchAddLoader";
import { ViewBatchLoader } from "../view/components/loader/Batches/ViewBatchLoader";
import { ViewCounterfeitLoader } from "../view/components/loader/Counterfeit/ViewCounterfeit";
import { ClientRequestLoader } from "../view/components/loader/Client/ClientRequestLoader";

import Login from "../view/containers/login";
import Home from "../view/containers/home";
import Counterfeit from "../view/containers/counterfeit";
import Scans from "../view/containers/scans";
import Alerts from "../view/containers/alerts";
import Faqs from "../view/containers/faqs";
import FaqsClient from "../view/containers/faqs-client";
import FaqsCategory from "../view/containers/faqs-category";
import FaqsUsers from "../view/containers/faqs-staff";
import PushNotifications from "../view/containers/push-notifications";
import ManageUser from "../view/containers/manage-users";

import ManageVideoCategory from "../view/containers/manage-video-category";
import ManageVideo from "../view/containers/manage-video";
import AddUser from "../view/containers/add-users";
import ViewUser from "../view/containers/view-user";

import ViewCounterfeit from "../view/containers/view-counterfeit";

import ViewCounterfeitMap from "../view/containers/view-counterfeit/MapCompnent";
import ManageProduct from "../view/containers/manage-product";
import ManageOrigin from "../view/containers/manage-origin";

import AddProduct from "../view/containers/manage-product/AddProduct";

import AddBatch from "../view/containers/manage-batches/AddBatch";

import ViewBatch from "../view/containers/manage-batches/ViewBatch";

import ManageBatches from "../view/containers/manage-batches";
import Setting from "../view/containers/setting";
import { MapLoader } from "../view/components/loader/Counterfeit/Map";
import ManageClient from "../view/containers/manage-clients";
import ManageClientRequests from "../view/containers/manage-clients/Request";
import ViewClient from "../view/containers/manage-clients/ViewClient";
import BatchView from "../view/containers/manage-clients/Batch/BatchView";
import ViewClientRequest from "../view/containers/manage-clients/Request/View";
import ManagePermission from "../view/containers/manage-clients/Permissions";
import ManageStaff from "../view/containers/manage-staff";
import ManageContact from "../view/containers/manage-contact";
import ManageContactClient from "../view/containers/manage-contact-client";
import ManageContactStaff from "../view/containers/manage-contact-staff";
import ManageTerms from "../view/containers/manage-terms";
import ManageTermsClient from "../view/containers/manage-terms-client";
import ManageTermsStaff from "../view/containers/manage-terms-staff";
import ManagePrivacy from "../view/containers/manage-privacy";
import ManagePrivacyClient from "../view/containers/manage-privacy-client";
import VideoTutorial from "../view/containers/video-tutorial";
import ManageStaffRequests from "../view/containers/manage-staff/Request";
import ViewStaff from "../view/containers/manage-staff/ViewStaff";
import ViewStaffRequest from "../view/containers/manage-staff/Request/View";
import ManageStaffPermission from "../view/containers/manage-staff/Permissions";
import EditClientRequest from "../view/containers/manage-clients/Request/edit";
import Reports from "../view/containers/reports";
import Batches from "../view/containers/reports/Batches";
import ReportCounterfeit from "../view/containers/reports/Counterfeit";
import ReportProduct from "../view/containers/reports/Product";
import ReportScan from "../view/containers/reports/Scan";
import ReportUsers from "../view/containers/reports/Users";
import TestOne from "../view/containers/testing/TestOne";
import TestTwo from "../view/containers/testing/TestTwo";
import Plans from "../view/containers/plans";
import CustomListing from "../view/containers/plans/CustomListing";
import AddPlan from "../view/containers/plans/addPlan";
import Transactions from "../view/containers/transactions";
import TransactionView from "../view/containers/transactions/TransactionView";
import SubscribedClients from "../view/containers/subscribedClients";
import SubscribedView from "../view/containers/subscribedClients/SubscribedView";
import Invoice from "../view/containers/invoice";
import Discounts from "../view/containers/discounts";
import ViewClientSubscription from "../view/containers/manage-clients/ViewClientSubscription";
import ManageRole from "../view/containers/manage-role";
import LandingPage from "../view/containers/landing-page";
import AboutUsPage from "../view/containers/about-us";
import GeneralEnquiriesPage from "../view/containers/enquiries/general";
import ViewDetail from "../view/components/enquiries/general/viewDetail";
import CareersEnquiriesPage from "../view/containers/enquiries/careers";
import ViewCareersDetail from "../view/components/enquiries/careers/viewDetails";
import SalesEnquiriesPage from "../view/containers/enquiries/sales";
import ViewSalesDetail from "../view/components/enquiries/sales/viewDetails";
import CareersPage from "../view/containers/careers";
import AddJobPage from "../view/containers/careers/addJob";
import EditJobPage from "../view/containers/careers/editJob";
import JobTitlePage from "../view/containers/careers/JobTitle";
import AccountManagementPage from "../view/containers/accountManagement";
import { AddTopicFormComponent } from "../view/components/accountManagement/addTopicFormComponent";
import AddTopicPage from "../view/containers/accountManagement/addTopic";
import EditTopicPage from "../view/containers/accountManagement/editTopic";
import ModuleListingPage from "../view/containers/accountManagement/moduleListing";
import ScanRules from "../view/containers/scan-rules";
import { AddScanRulesComponent } from "../view/components/AddScanRules";
import AddScanRules from "../view/containers/add-scan-rules";
import EditScanRule from "../view/containers/edit-scan-rules";
import CounterfeitId from "../view/containers/counterfeit-id";
import PlanContact from "../view/containers/plans/PlanContact";
import AddCustom from "../view/containers/plans/addCustom";
import PlanView from "../view/containers/plans/PlanView";
import CustomView from "../view/containers/plans/CustomView";

export const routesArray = [
  {
    path: AppRoutes.LOGIN,
    exact: true,
    name: "Login",
    breadcrumb: "Login",
    component: Login,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.DASHBOARD,
    exact: true,
    name: "Dashboard",
    breadcrumb: "Dashboard",
    component: Home,
    isAuthenticatedRoute: true,
    loader: DashboardLoader,
  },
  {
    path: AppRoutes.CLARITY,
    exact: true,
    name: "Manage Clarity",
    breadcrumb: "Manage Clarity",
    component: Counterfeit,
    isAuthenticatedRoute: false,
  },
  {
    path: AppRoutes.COUNTERFEIT,
    exact: true,
    name: "Counterfeit",
    breadcrumb: "Counterfeit",
    component: Counterfeit,
    isAuthenticatedRoute: true,
    loader: CounterfeitLoader,
  },
  {
    path: AppRoutes.COUNTERFEIT_VIEW,
    exact: true,
    name: "View",
    breadcrumb: "View",
    component: ViewCounterfeit,
    isAuthenticatedRoute: true,
    loader: ViewCounterfeitLoader,
  },
  {
    path: AppRoutes.COUNTERFEIT_MAP_VIEW,
    exact: true,
    name: "Map",
    breadcrumb: "Map",
    component: ViewCounterfeitMap,
    isAuthenticatedRoute: true,
    loader: MapLoader,
  },
  {
    path: AppRoutes.SCANS,
    exact: true,
    name: "Scans",
    breadcrumb: "Scans",
    component: Scans,
    isAuthenticatedRoute: true,
    loader: ScanLoader,
  },
  {
    path: AppRoutes.ALERTS,
    exact: true,
    name: "Alerts",
    breadcrumb: "Alerts",
    component: Alerts,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.SCAN_RULES,
    exact: true,
    name: "Scan Rules",
    breadcrumb: "Scan Rules",
    component: ScanRules,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.Add_SCAN_RULES,
    exact: true,
    name: "Add Scan Rules",
    breadcrumb: "Add Scan Rules",
    component: AddScanRules,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.Edit_SCAN_RULES,
    exact: true,
    name: "Edit Scan Rules",
    breadcrumb: "Edit Scan Rules",
    component: EditScanRule,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.COUNTERFEIT_ID,
    exact: true,
    name: "Scan Rules",
    breadcrumb: false,
    component: CounterfeitId,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.MANAGE_USER,
    exact: true,
    name: "Manage User",
    breadcrumb: "Manage User",
    component: ManageUser,
    isAuthenticatedRoute: true,
    loader: UsersLoader,
  },
  {
    path: AppRoutes.ADD_USER,
    exact: true,
    name: "Add User",
    breadcrumb: "Add User",
    component: AddUser,
    isAuthenticatedRoute: true,
    loader: AddUserLoader,
  },
  {
    path: AppRoutes.VIEW_USER,
    exact: true,
    name: "View",
    breadcrumb: "custom", // for custom breadcrumb like user name
    component: ViewUser,
    isAuthenticatedRoute: true,
    loader: ViewUserLoader,
  },
  {
    path: AppRoutes.MANAGE_VIDEO_CATEGORY,
    exact: true,
    name: "Manage Video",
    breadcrumb: "Manage Video",
    component: ManageVideoCategory,
    isAuthenticatedRoute: true,
    loader: CategoryLoader,
  },
  {
    path: AppRoutes.MANAGE_VIDEO,
    exact: true,
    name: "Manage Video",
    breadcrumb: "custom",
    component: ManageVideo,
    isAuthenticatedRoute: true,
    loader: VideoLoader,
  },
  {
    path: AppRoutes.MANAGE_PRODUCT,
    exact: true,
    name: "Manage Product",
    breadcrumb: "Manage Product",
    component: ManageProduct,
    isAuthenticatedRoute: true,
    loader: ProductLoader,
  },
  {
    path: AppRoutes.ADD_PRODUCT,
    exact: true,
    name: "Add Product",
    breadcrumb: "Add Product",
    component: AddProduct,
    isAuthenticatedRoute: true,
    loader: ProductAddLoader,
  },
  {
    path: AppRoutes.EDIT_PRODUCT,
    exact: true,
    name: "Edit Product",
    breadcrumb: "custom",
    component: AddProduct,
    isAuthenticatedRoute: true,
    loader: ProductAddLoader,
  },

  {
    path: AppRoutes.MANAGE_BATCH,
    exact: true,
    name: "Batches",
    breadcrumb: "Batch Listing",
    component: ManageBatches,
    isAuthenticatedRoute: true,
    loader: BatchesLoader,
  },
  {
    path: AppRoutes.ADD_BATCH,
    exact: true,
    name: "Add Batch",
    breadcrumb: "Add Batch",
    component: AddBatch,
    isAuthenticatedRoute: true,
    loader: BatchAddLoader,
  },
  {
    path: AppRoutes.EDIT_BATCH,
    exact: true,
    name: "Edit Batch",
    breadcrumb: "custom",
    component: AddBatch,
    isAuthenticatedRoute: true,
    loader: BatchAddLoader,
  },
  {
    path: AppRoutes.VIEW_BATCH,
    exact: true,
    name: "View Batch",
    breadcrumb: "custom",
    component: ViewBatch,
    isAuthenticatedRoute: true,
    loader: ViewBatchLoader,
  },
  {
    path: AppRoutes.MANAGE_ORIGIN,
    exact: true,
    name: "Manage Origin",
    breadcrumb: "Manage Origin",
    component: ManageOrigin,
    isAuthenticatedRoute: true,
    loader: OriginLoader,
  },

  {
    path: AppRoutes.MANAGE_CLIENTS,
    exact: true,
    name: "Manage Clients",
    breadcrumb: "Client listing details",
    component: ManageClient,
    isAuthenticatedRoute: true,
    loader: CategoryLoader,
  },
  {
    path: AppRoutes.MANAGE_CLIENTS_REQUESTS,
    exact: true,
    name: "Client Requests",
    breadcrumb: "View Client Requests",
    component: ManageClientRequests,
    isAuthenticatedRoute: true,
    loader: ClientRequestLoader,
  },
  {
    path: AppRoutes.VIEW_CLIENT_REQUEST,
    exact: true,
    name: "Client Request Details",
    breadcrumb: "custom",
    component: ViewClientRequest,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.VIEW_CLIENT,
    exact: true,
    name: "View Details",
    breadcrumb: "custom",
    component: ViewClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CLIENT_SUBSCRIPTION,
    exact: true,
    name: "Manage Client",
    breadcrumb: "Subscription",
    component: ViewClientSubscription,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.VIEW_CLIENT_BATCH,
    exact: true,
    name: "Batches History",
    breadcrumb: "Batches History",
    component: BatchView,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_CLIENTS_PERMISSION,
    exact: true,
    name: "View Details",
    breadcrumb: "Permissions",
    component: ManagePermission,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Manage Staff",
    component: ManageStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_LIST,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Staff Listing",
    component: ManageStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_ROLE,
    exact: true,
    name: "Manage Staff",
    breadcrumb: "Manage Roles",
    component: ManageRole,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_REQUESTS,
    exact: true,
    name: "Staff Onboarding",
    breadcrumb: "Staff Details",
    component: ManageStaffRequests,
    isAuthenticatedRoute: true,
    loader: ClientRequestLoader,
  },
  {
    path: AppRoutes.VIEW_STAFF_REQUEST,
    exact: true,
    name: "View Details",
    breadcrumb: "custom",
    component: ViewStaffRequest,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.VIEW_STAFF,
    exact: true,
    name: "View Staff",
    breadcrumb: "custom",
    component: ViewStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_STAFF_PERMISSION,
    exact: true,
    name: "View Staff",
    breadcrumb: "Permissions",
    component: ManageStaffPermission,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SETTING,
    exact: true,
    name: "Settings",
    breadcrumb: "Settings",
    component: Setting,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.EDIT_CLIENT_REQUEST,
    exact: true,
    name: "Edit Detail",
    breadcrumb: "custom",
    component: EditClientRequest,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CONTACT_USER,
    exact: true,
    name: "Contact",
    breadcrumb: "Contact",
    component: ManageContact,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CONTACT_APP,
    exact: true,
    name: "Contact - App",
    breadcrumb: "Contact",
    component: ManageContact,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CONTACT_CLIENT,
    exact: true,
    name: "Contact - Client",
    breadcrumb: "Contact",
    component: ManageContactClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CONTACT_STAFF,
    exact: true,
    name: "Contact - Staff",
    breadcrumb: "Contact",
    component: ManageContactStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_USER,
    exact: true,
    name: "Terms and Conditions",
    breadcrumb: "CMS",
    component: ManageTerms,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION,
    exact: true,
    name: "Terms and Conditions - App",
    breadcrumb: "CMS",
    component: ManageTerms,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_CLIENT,
    exact: true,
    name: "Terms and Conditions - Client",
    breadcrumb: "CMS",
    component: ManageTermsClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TERMS_AND_CONDITION_STAFF,
    exact: true,
    name: "Terms and Conditions - Staff",
    breadcrumb: "CMS",
    component: ManageTermsStaff,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS_PRIVACY_POLICY_USER,
    exact: true,
    name: "Privacy Policy",
    breadcrumb: "Privacy Policy",
    component: ManagePrivacy,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS_PRIVACY_POLICY,
    exact: true,
    name: "Privacy Policy - App",
    breadcrumb: "Privacy Policy",
    component: ManagePrivacy,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS_PRIVACY_POLICY_CLIENT,
    exact: true,
    name: "Privacy Policy - Client",
    breadcrumb: "Privacy Policy",
    component: ManagePrivacyClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS_PRIVACY_POLICY_STAFF,
    exact: true,
    name: "Privacy Policy - Staff",
    breadcrumb: "Privacy Policy",
    component: ManagePrivacyClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS_VIDEO_TUTORIAL,
    exact: true,
    name: "Video Tutorial",
    breadcrumb: "Video Tutorial",
    component: VideoTutorial,
    isAuthenticatedRoute: true,
  },
  // {
  //   path: AppRoutes.FAQS,
  //   exact: true,
  //   name: "FAQs",
  //   breadcrumb: "Faqs",
  //   component: Faqs,
  //   isAuthenticatedRoute: true,
  // },
  {
    path: AppRoutes.FAQS_USERS,
    exact: true,
    name: "FAQs",
    breadcrumb: "Faqs",
    component: Faqs,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.FAQS_APPUSERS,
    exact: true,
    name: "FAQs - App",
    breadcrumb: "Faqs",
    component: Faqs,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.FAQS_CLIENTSUSERS,
    exact: true,
    name: "FAQs - Client",
    breadcrumb: "Faqs",
    component: FaqsClient,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.FAQS_CATEGORY,
    exact: true,
    name: "Category",
    breadcrumb: "Faqs",
    component: FaqsCategory,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.FAQS_STAFFUSERS,
    exact: true,
    name: "FAQs - Staff",
    breadcrumb: "Faqs",
    component: FaqsUsers,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CMS,
    exact: true,
    name: "Push Notifications",
    breadcrumb: "CMS",
    component: PushNotifications,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PUSH_NOTIFICATIONS,
    exact: true,
    name: "Push Notifications",
    breadcrumb: "Push Notifications",
    component: PushNotifications,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.REPORTS,
    exact: true,
    name: "Reports",
    breadcrumb: "Reports",
    component: Batches,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.BATCHES_REPORTS,
    exact: true,
    name: "Batches",
    breadcrumb: "Batches",
    component: Batches,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.COUNTERFEIT_REPORTS,
    exact: true,
    name: "Counterfeit",
    breadcrumb: "Counterfeit",
    component: ReportCounterfeit,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PRODUCT_REPORTS,
    exact: true,
    name: "Product",
    breadcrumb: "Product",
    component: ReportProduct,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.USERS_REPORTS,
    exact: true,
    name: "Users",
    breadcrumb: "Users",
    component: ReportUsers,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SCAN_REPORTS,
    exact: true,
    name: "Scan",
    breadcrumb: "Scan",
    component: ReportScan,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TEST_ONE,
    exact: true,
    name: "Test One",
    breadcrumb: "Test One",
    component: TestOne,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TEST_TWO,
    exact: true,
    name: "Test Two",
    breadcrumb: "Test Two",
    component: TestTwo,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MANAGE_SUBSCRIPTION,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Manage Subscription",
    component: Plans,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PLANS,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Plans",
    component: Plans,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PLANS_PAID,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Paid",
    component: Plans,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ADDPLAN,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Add Plan",
    component: AddPlan,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ADDCUSTOM,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Add Custom",
    component: AddCustom,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.Edit_plan,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Edit Plan",
    component: AddPlan,
    isAuthenticatedRoute: true,
    loader: BatchAddLoader,
  },
  {
    path: AppRoutes.Edit_custom_plan,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Edit Plan",
    component: AddCustom,
    isAuthenticatedRoute: true,
    loader: BatchAddLoader,
  },
  {
    path: AppRoutes.PLANS_CUSTOM,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Custom",
    component: CustomListing,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PLANS_CONTACT,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Contact",
    component: PlanContact,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TRANSACTIONS,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Transactions",
    component: Transactions,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.TRANSACTIONSVIEW,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Transactions View",
    component: TransactionView,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.PLANVIEW,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Plan View",
    component: PlanView,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CUSTOMVIEW,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Custom View",
    component: CustomView,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SUBSCRIBED_CLIENTS,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Subscribed Clients",
    component: SubscribedClients,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.SUBSCRIBEDVIEW,
    exact: true,
    name: "Manage Subscription",
    breadcrumb: "Subscribed View",
    component: SubscribedView,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.INVOICE,
    exact: true,
    name: "Invoice",
    breadcrumb: "Invoice",
    component: Invoice,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.DISCOUNT,
    exact: true,
    name: "Discounts",
    breadcrumb: "Discounts",
    component: Discounts,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.LANDING_PAGE_CLIENT,
    exact: true,
    name: "Landing Page",
    breadcrumb: "Landing Page",
    component: LandingPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ABOUT_US,
    exact: true,
    name: "About us",
    breadcrumb: "About us",
    component: AboutUsPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.CAREERS,
    exact: true,
    name: "Careers",
    breadcrumb: "Careers",
    component: CareersPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ADDJOB_CAREERS,
    exact: true,
    name: "Add Job",
    breadcrumb: "Add Job",
    component: AddJobPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.EDITJOB_CAREERS,
    exact: true,
    name: "Edit Job",
    breadcrumb: "Edit Job",
    component: EditJobPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.JOBTITLE_CAREERS,
    exact: true,
    name: "Job Title",
    breadcrumb: "Job Title",
    component: JobTitlePage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ACCOUNT_MANAGEMENT,
    exact: true,
    name: "Account Management",
    breadcrumb: "Account Management",
    component: AccountManagementPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ADDTOPIC_ACCOUNT_MANAGEMENT,
    exact: true,
    name: "Add Topic",
    breadcrumb: "Add Topic",
    component: AddTopicPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.EDITTOPIC_ACCOUNT_MANAGEMENT,
    exact: true,
    name: "Edit Topic",
    breadcrumb: "Edit Topic",
    component: EditTopicPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.MODULE_LISTING_ACOUNT_MANAGEMENT,
    exact: true,
    name: "Module List",
    breadcrumb: "Module List",
    component: ModuleListingPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES,
    exact: true,
    name: "Enquiries",
    breadcrumb: "Enquiries",
    component: GeneralEnquiriesPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_GENERAL,
    exact: true,
    name: "General",
    breadcrumb: "General",
    component: GeneralEnquiriesPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_GENERAL_DETAILS,
    exact: true,
    name: "General",
    breadcrumb: "View Details",
    component: ViewDetail,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_CAREERS,
    exact: true,
    name: "Careers",
    breadcrumb: "Careers",
    component: CareersEnquiriesPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_CAREERS_DETAILS,
    exact: true,
    name: "Careers",
    breadcrumb: "View Details",
    component: ViewCareersDetail,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_SALES,
    exact: true,
    name: "Sales",
    breadcrumb: "Sales",
    component: SalesEnquiriesPage,
    isAuthenticatedRoute: true,
  },
  {
    path: AppRoutes.ENQUIRIES_SALES_DETAILS,
    exact: true,
    name: "Sales",
    breadcrumb: "View Details",
    component: ViewSalesDetail,
    isAuthenticatedRoute: true,
  },
];
