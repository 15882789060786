import React, { Component, useState } from "react";
import { toast } from "react-toastify";
import { ApiRoutes, websiteRegex } from "../../../config";
import { ApiHelper } from "../../../helpers";
import { InputTextField, SubmitButton } from "../../components/forms";
import { contactValidator } from "../../../validators";
import { pushValidator } from "../../../validators";

//import { userValidator } from '../../../validators';
import "./manage-contact.css";
import { Breadcrumbs } from "../../components/partial/Breadcrumbs";
import { ContactLoader } from "../../components/loader/Cms/ContactLoader";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class ManageContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      address: "",
      loading: false,
      userDetail: "",
      errors: null,
      contact: [],
      pageLoader: false,
    };
  }

  componentDidMount = () => {
    this.setState(
      {
        userDetail: this.props.location.pathname.split("/")[2], //[3],
      },
      () => this.handleQueryParams()
    );

    this.handleQueryParams();
  };
  handleQueryParams = async () => {
    this.fetchDetails();
  };

  componentDidUpdate = ({ location: { search: prevSearch } }, prevState) => {
    const splitValue = this.props.location.pathname.split("/")[2]; //[3];
    if (prevState.userDetail !== splitValue) {
      this.setState(
        {
          userDetail: splitValue,
        },
        () => this.handleQueryParams()
      );
    }
  };
  fetchDetails = async () => {
    const { userDetail } = this.state;
    this.setState({
      pageLoader: true,
    });
    let response = undefined;
    if (userDetail && userDetail === "Web") {
      const url = ApiRoutes.GET_CONTACT_WEB_NEW.url;
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.GET_CONTACT_WEB_NEW.service,
        url,
        ApiRoutes.GET_CONTACT_WEB_NEW.method,
        ApiRoutes.GET_CONTACT_WEB_NEW.authenticate,
        undefined,
        undefined,
        undefined
      );
      this.setState({
        pageLoader: false,
      });
      if (response && response?.isError) {
      } else {
        const contact = response.data.data;
        this.setState({ contact: contact[0], errors: null });
      }
    } else if (userDetail && userDetail !== "Web") {
      const url = ApiRoutes.GET_CONTACT.url + "?users=" + userDetail;
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.GET_CONTACT.service,
        url,
        ApiRoutes.GET_CONTACT.method,
        ApiRoutes.GET_CONTACT.authenticate,
        undefined,
        undefined
      );
      this.setState({
        pageLoader: false,
      });
      if (response && response?.isError) {
      } else {
        const { contact } = response.data.data;
        this.setState({ contact: contact[0], errors: null });
      }
    }
  };

  updateDetails = async (payload) => {
    const { contact, userDetail } = this.state;
    this.setState({
      loading: true,
    });
    let response = undefined;
    if (userDetail && userDetail === "Web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.service,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.url,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.method,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.authenticate,
        undefined,
        payload
      );
    } else if (userDetail && userDetail !== "Web") {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.UPDATE_CONTACT.service,
        ApiRoutes.UPDATE_CONTACT.url.replace(":id", contact?.id),
        ApiRoutes.UPDATE_CONTACT.method,
        ApiRoutes.UPDATE_CONTACT.authenticate,
        undefined,
        payload
      );
    }

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
        loading: false,
      });
    } else {
      toast.success(response.messages[0]);
      this.setState({
        loading: false,
      });
      this.fetchDetails();
    }
  };
  saveDetails = async (payload) => {
    const { userDetail } = this.state;

    this.setState({
      loading: true,
    });

    let response = undefined;
    if (userDetail && userDetail === "Web") {
      response = await new ApiHelper().NewFetchFromServer(
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.service,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.url,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.method,
        ApiRoutes.UPDATE_CONTACT_WEB_NEW.authenticate,
        undefined,
        payload
      );
    } else if (userDetail && userDetail !== "Web") {
      response = await new ApiHelper().FetchFromServer(
        ApiRoutes.ADD_CONTACT.service,
        ApiRoutes.ADD_CONTACT.url,
        ApiRoutes.ADD_CONTACT.method,
        ApiRoutes.ADD_CONTACT.authenticate,
        undefined,
        payload
      );
    }

    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
        loading: false,
      });
    } else {
      toast.success(response.messages[0]);
      this.setState({
        loading: false,
      });
      this.fetchDetails();
    }
  };

  handleChange = async (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "phone" && isNaN(value)) {
      return;
    }
    this.setState({
      contact: {
        ...this.state.contact,
        [name]: value.trimLeft(),
      },
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });

    var errorsHandle = [];
    if (name == "whatsappLink") {
      const validateWhatsappLink = await this.validateRegex(
        "whatsappLink",
        value
      );
      if (!validateWhatsappLink) errorsHandle.push("whatsappLink");
    }

    if (name == "telegramLink") {
      const validateTelegramLink = await this.validateRegex(
        "telegramLink",
        value
      );
      if (!validateTelegramLink) errorsHandle.push("telegramLink");
    }

    if (name == "facebook") {
      const validateFacebookLink = await this.validateRegex("facebook", value);

      if (!validateFacebookLink) errorsHandle.push("facebook");
    }
    if (name == "twitter") {
      const validatetwitterLink = await this.validateRegex("twitter", value);
      if (!validatetwitterLink) errorsHandle.push("twitter");
    }
    if (name == "linkedin") {
      const validateLinkdinLink = await this.validateRegex("linkedin", value);
      if (!validateLinkdinLink) errorsHandle.push("linkedin");
    }
    if (errorsHandle.length > 0) {
      return;
    }
    const { contact } = this.state;
    let payload = {
      email: name == "email" ? value : contact?.email,
      phone: name == "phone" ? value : contact?.phone,
      address: name == "address" ? value : contact?.address,
      whatsappLink: name == "whatsappLink" ? value : contact?.whatsappLink,
      telegramLink: name == "telegramLink" ? value : contact?.telegramLink,
      facebook: name == "facebook" ? value : contact?.facebook,
      linkedin: name == "linkedin" ? value : contact?.linkedin,
      twitter: name == "twitter" ? value : contact?.twitter,
      instagram: name == "instagram" ? value : contact?.instagram,
    };
    let { isValid, errors } = contactValidator(payload);
    if (isValid) {
    } else {
      this.setState({
        loading: false,
        errors: errors,
      });
      return;
    }
  };

  validateRegex = (field, link) => {
    console.log(field, link);
    console.log(websiteRegex.test(link));

    if (link && !websiteRegex.test(link)) {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: "Please enter valid link",
        },
      });
      return false;
    }
    return true;
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { userDetail, contact } = this.state;

    var errorsHandle = [];

    const validateWhatsappLink = await this.validateRegex(
      "whatsappLink",
      contact?.whatsappLink
    );
    if (!validateWhatsappLink) errorsHandle.push("whatsappLink");

    const validateTelegramLink = await this.validateRegex(
      "telegramLink",
      contact?.telegramLink
    );
    if (!validateTelegramLink) errorsHandle.push("telegramLink");

    const validateFacebookLink = await this.validateRegex(
      "facebook",
      contact?.facebook
    );

    if (!validateFacebookLink) errorsHandle.push("facebook");

    const validatetwitterLink = await this.validateRegex(
      "twitter",
      contact?.twitter
    );
    if (!validatetwitterLink) errorsHandle.push("twitter");

    const validateInstagramLink = await this.validateRegex(
      "instagram",
      contact?.instagram
    );
    if (!validateInstagramLink) errorsHandle.push("instagram");

    const validateLinkdinLink = await this.validateRegex(
      userDetail == "Web" ? "linkedin" : "linkedIn",
      userDetail == "Web" ? contact?.linkedin : contact?.linkediIn
    );
    if (!validateLinkdinLink)
      errorsHandle.push(userDetail == "Web" ? "linkedin" : "linkedIn");

    console.log(errorsHandle);
    if (errorsHandle.length > 0) {
      return;
    }

    let payload = [];
    if (userDetail !== "Web") {
      if (contact?.id) {
        payload = {
          email: contact?.email,
          phone: contact?.phone,
          address: contact?.address,
          whatsappLink: contact?.whatsappLink,
          telegramLink: contact?.telegramLink,
          facebook: contact?.facebook,
          linkedIn: contact?.linkedIn,
          twitter: contact?.twitter,
          instagram: contact?.instagram,
        };
      } else {
        payload = {
          users: userDetail,
          email: contact?.email,
          phone: contact?.phone,
          address: contact?.address,
          whatsappLink: contact?.whatsappLink,
          telegramLink: contact?.telegramLink,
          facebook: contact?.facebook,
          linkedIn: contact?.linkedIn,
          twitter: contact?.twitter,
          instagram: contact?.instagram,
        };
      }
    } else if (userDetail === "Web") {
      if (contact?.id) {
        payload = {
          email: contact?.email,
          phone: contact?.phone,
          address: contact?.address,
          whatsappLink: contact?.whatsappLink,
          telegramLink: contact?.telegramLink,
          facebook: contact?.facebook,
          linkedin: contact?.linkedin,
          twitter: contact?.twitter,
          instagram: contact?.instagram,
        };
      } else {
        payload = {
          email: contact?.email,
          phone: contact?.phone,
          address: contact?.address,
          whatsappLink: contact?.whatsappLink,
          telegramLink: contact?.telegramLink,
          facebook: contact?.facebook,
          linkedin: contact?.linkedin,
          twitter: contact?.twitter,
          instagram: contact?.instagram,
        };
      }
    }

    let { isValid, errors } = contactValidator(payload);
    if (isValid) {
      if (contact?.id) {
        this.updateDetails(payload);
      } else {
        this.saveDetails(payload);
      }
    } else {
      this.setState({
        loading: false,
        errors: errors,
      });
      return;
    }
  };

  handleCancel = () => {
    this.fetchDetails();
  };
  render() {
    const { contact, errors, pageLoader, userDetail } = this.state;
    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <div className="container-fluid fluid-pd-2 mainContactSet">
        <h1 className="h3-head-mob d-md-none">Contact</h1>
        <Breadcrumbs pathname={pathname} />
        <div className="card mb-4 card-main">
          {pageLoader ? (
            <ContactLoader />
          ) : (
            <>
              <div className="card-header  py-7550  align-items-center justify-content-between">
                <div className="row">
                  <div className="col-md-12">
                    <h6 className="font-book ">
                      Please enter the contact details below and use comma (,)
                      if there is more then one value
                    </h6>
                  </div>
                </div>
              </div>
              <div className="card-body card-table">
                <form className="" onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="email"
                        label={"Email"}
                        className={"form-control-product"}
                        name="email"
                        onChange={this.handleChange}
                        disabled={false}
                        value={contact?.email}
                        errorMessage={errors?.email ? errors?.email : ""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="phone"
                        label={"Phone"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="phone"
                        value={contact?.phone}
                        errorMessage={errors?.phone ? errors?.phone : ""}
                      />

                      <div className="form-group">
                        <label
                          for="phone"
                          class="form-control-label form-control-product"
                        >
                          Phone
                        </label>

                        <PhoneInput
                          country={"au"}
                          onChange={(phone) => this.setState({ phone })}
                          id="phone"
                          label={"Phone"}
                          className={"form-control-product-input"}
                          disabled={false}
                          name="phone"
                          value={contact?.phone}
                          errorMessage={errors?.phone ? errors?.phone : ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label
                        htmlFor="product_description"
                        className="form-control-label"
                      >
                        Address
                      </label>
                      <textarea
                        className="form-control form-control-textarea wt-resize"
                        cols="50"
                        id="address"
                        onChange={this.handleChange}
                        disabled={false}
                        aria-describedby="category_nameHelp"
                        value={contact?.address}
                        rows="4"
                        name={"address"}
                        maxLength={200}
                        style={{ marginBottom: "35px" }}
                      >
                        {this.state.address}
                      </textarea>
                      <div className={"text-danger"}>
                        {errors?.address ? errors?.address : ""}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="whatsapp"
                        label={"WhatsApp Link"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="whatsappLink"
                        value={contact?.whatsappLink}
                        errorMessage={
                          errors?.whatsappLink ? errors?.whatsappLink : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="telegram"
                        label={"Telegram Link"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="telegramLink"
                        value={contact?.telegramLink}
                        errorMessage={
                          errors?.telegramLink ? errors?.telegramLink : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="facebook"
                        label={"Facebook"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="facebook"
                        value={contact?.facebook}
                        errorMessage={errors?.facebook ? errors?.facebook : ""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="linkedin"
                        label={"Linked In"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name={userDetail == "Web" ? "linkedin" : "linkedIn"}
                        value={
                          userDetail == "Web"
                            ? contact?.linkedin
                            : contact?.linkedIn
                        }
                        errorMessage={
                          userDetail == "Web"
                            ? errors?.linkedin
                              ? errors?.linkedin
                              : ""
                            : errors?.linkedIn
                            ? errors?.linkedIn
                            : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="twitter"
                        label={"Twitter"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="twitter"
                        value={contact?.twitter}
                        errorMessage={errors?.twitter ? errors?.twitter : ""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <InputTextField
                        id="instagram"
                        label={"Instagram"}
                        className={"form-control-product"}
                        onChange={this.handleChange}
                        disabled={false}
                        name="instagram"
                        value={contact?.instagram}
                        errorMessage={
                          errors?.instagram ? errors?.instagram : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-12 footer-form mt-0 pt-0 mb-0">
                    <div className="d-flex align-items-center justify-content-center contactForm_admin">
                      <button
                        className="btn btn-primary cancel-btn"
                        style={{ height: "45px", width: "190px" }}
                        type="button"
                        onClick={this.handleCancel}
                      >
                        {" "}
                        CANCEL
                      </button>

                      <SubmitButton
                        className={"submit-btn"}
                        text={contact?._id ? "Edit" : "Save"}
                        isLoading={this.state.loading}
                        onSubmit={this.handleSubmit}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default ManageContact;
