import React from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import listIcon from "../../../assets/img/list.svg";
import editIcon from "../../../assets/img/edit-3.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import { Button } from "react-bootstrap";
import { InputTextField, SubmitButton } from "../forms";

export const CounterfeitIdRow = ({ scanData, onEdit, onDelete }) => {
  const { scanId = "", batch = {}, product = {}, user = {} } = scanData;
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { first_name = "", other_info = {} } = user || {};
  return (
    <>
      <tr>
        <td className={"cursor-pointer"} style={{ color: "#0A1F44" }}>
          XYZ345
        </td>
        <td>09:47 Hrs | 30/12/2020</td>
        <td className="pname_scan">PHL Admin</td>
        <td className={"cursor-pointer"}>
          <div
            className="d-flex align-items-start"
            style={{
              width: "100%",
            }}
          >
            <Button
              className="btn btn-secondary th-btn edit-tb action-block-btn"
              onClick={onEdit}
            >
              <span>
                <img src={editIcon} alt="" className={"img-main"} />
                <img src={editIconWhite} alt="" className={"img-hover"} />
              </span>
              Edit
            </Button>
            <Button
              variant="danger"
              className="btn th-btn delete-tb action-block-btn"
              onClick={onDelete}
            >
              <span>
                <img src={trashIcon} alt="" className={"img-main"} />
                <img src={trashIconWhite} alt="" className={"img-hover"} />
              </span>
              Delete
            </Button>
          </div>
        </td>
      </tr>
    </>
  );
};
