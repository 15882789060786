import React from "react";
import { Button } from "react-bootstrap";
import editIcon from "../../../../assets/img/edit-3.svg";
import editIconWhite from "../../../../assets/img/edit-3-white.svg";
import trashIcon from "../../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../../assets/img/trash-2-white.svg";
import { useState } from "react";

export const ModuleListRow = ({ IndividualModuleDetail, onEdit, onDelete }) => {
  const [moduleEdit, setModuleEdit] = useState(IndividualModuleDetail);

  console.log(IndividualModuleDetail, ":::individual");
  return (
    <tr>
      <td>
        <div
          style={{
            width: "100%",
            whiteSpace: "normal",
            color: "#000",
          }}
        >
          <div>{moduleEdit?.name}</div>
        </div>
      </td>

      <td className="switchBox">
        <div
          className="d-flex align-items-start justify-content-end"
          style={{
            width: "100%",
          }}
        >
          <Button
            className="btn btn-secondary th-btn edit-tb action-block-btn"
            onClick={onEdit}
          >
            <span>
              <img src={editIcon} alt="" className={"img-main"} />
              <img src={editIconWhite} alt="" className={"img-hover"} />
            </span>
            Edit
          </Button>
          <Button
            variant="danger"
            className="btn th-btn delete-tb action-block-btn"
            onClick={onDelete}
          >
            <span>
              <img src={trashIcon} alt="" className={"img-main"} />
              <img src={trashIconWhite} alt="" className={"img-hover"} />
            </span>
            Delete
          </Button>
        </div>
      </td>
    </tr>
  );
};
