import React from "react";
import { InputTextField, SubmitButton } from "../forms";
import AsyncSelect from "react-select/async";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../config";
import { BatchInputLoader } from "../loader/Resusable/BatchInput";
import { ApiHelper } from "../../../helpers";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

export const AddCustomFormComponent = ({
  handleSubmit,
  onChange,
  errors,
  name,
  onSelect,
  price,
  description,
  users,
  printCodes,
  domainName,
  isEditMode,
  isAdding,
  isLoading,
  clientList,
  clientId,
  remark,
  permissionType,
  exportType,
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),

    indicatorSeparator: (provided, state) => ({
      ...provided,
      background: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Medium",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "CircularStd-Book",
    }),
  };

  const loadClient = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = clientList.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const permissionTypeOptions = [
    { value: "Read Only", label: "Read Only" },
    { value: "Read & Write", label: "Read & Write" },
  ];

  const loadPermissionType = async (inputValue) => {
    let result;
    return await new Promise(async (resolve, reject) => {
      result = permissionTypeOptions.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const exportTypeOptions = [
    { value: "PDF", label: "PDF" },
    { value: "CSV", label: "CSV" },
  ];

  const loadExportType = async (inputValue) => {
    let result;
    return await new Promise(async (resolve, reject) => {
      result = exportTypeOptions.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  const domainOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];

  const loadDomain = async (inputValue) => {
    let result;
    return await new Promise(async (resolve, reject) => {
      result = domainOptions.filter((x) =>
        x.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  return (
    <div style={{ padding: "14px 21px" }}>
      {isLoading ? (
        <BatchInputLoader />
      ) : (
        <form
          className="user_add m-0 addPlanform product_add"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-md-12 row_col_md_bg">
              <div className="row">
                <div className="col-md-4">
                  <InputTextField
                    id="price"
                    label={"Monthly Price ($)"}
                    className={"form-control-user"}
                    name="price"
                    value={price}
                    onChange={onChange}
                    errorMessage={errors && errors.price ? errors.price : ""}
                    maxLength={30}
                    placeholder={"Monthly Price"}
                  />
                </div>

                <div className="col-md-4">
                  <label className={`form-control-label`}>Client </label>
                  <AsyncSelect
                    loadOptions={loadClient}
                    placeholder={"--Select--"}
                    styles={customStyles}
                    maxMenuHeight={200}
                    defaultOptions={clientList}
                    value={clientId}
                    onChange={(selectedOption) =>
                      onSelect(selectedOption, "clientId")
                    }
                    noOptionsMessage={() =>
                      "No client found related to your search"
                    }
                  />
                  <div className={"text-danger"}>
                    {errors && errors.clientId ? errors.clientId : ""}
                  </div>
                </div>

                <div className="col-md-4">
                  <InputTextField
                    id="email"
                    label={"Remark"}
                    className={"form-control-user"}
                    name="remark"
                    value={remark}
                    onChange={onChange}
                    maxLength={30}
                    errorMessage={errors && errors.remark ? errors.remark : ""}
                    placeholder={"Enter remark"}
                  />
                </div>

                <div className="col-md-4" style={{ marginTop: "15px" }}>
                  <InputTextField
                    id="email"
                    label={"Plan Name"}
                    className={"form-control-user"}
                    name="name"
                    value={name}
                    onChange={onChange}
                    errorMessage={errors && errors.name ? errors.name : ""}
                    maxLength={30}
                    placeholder={"Enter Plan Name"}
                  />
                </div>

                <div className="col-md-12" style={{ marginTop: "15px" }}>
                  <label htmlFor="brief" className="form-control-label">
                    Brief
                  </label>
                  <textarea
                    className="form-control form-control-textarea wt-resize"
                    cols="50"
                    id="brief"
                    aria-describedby="category_nameHelp"
                    rows="3"
                    name={"description"}
                    value={description}
                    onChange={onChange}
                    maxLength={150}
                    style={{
                      minHeight: "96px",
                      maxHeight: "96px",
                      padding: "9px 0 0 0",
                    }}
                    placeholder={"Enter brief of plan, upto 150 characters"}
                  />
                  <div className={"text-danger"}>
                    {errors && errors.description ? errors.description : ""}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 row_col_md_bg">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="custom_head_title">Dynamic Details</h5>
                </div>

                <div className="col-md-3">
                  <InputTextField
                    id="email"
                    label={"Number of staff"}
                    className={"form-control-user"}
                    name="users"
                    value={users}
                    onChange={onChange}
                    errorMessage={errors && errors.users ? errors.users : ""}
                    maxLength={10}
                    placeholder={"Enter Number of Users"}
                  />
                </div>
                <div className="col-md-3">
                  <label className={`form-control-label`}>
                    Permission Type
                  </label>
                  <AsyncSelect
                    loadOptions={loadPermissionType}
                    placeholder={"--Select--"}
                    styles={customStyles}
                    maxMenuHeight={200}
                    defaultOptions={permissionTypeOptions}
                    value={permissionType}
                    onChange={(selectedOption) =>
                      onSelect(selectedOption, "permissionType")
                    }
                    noOptionsMessage={() =>
                      "No permission type found related to your search"
                    }
                  />
                  <div className={"text-danger"}>
                    {errors && errors.permissionType
                      ? errors.permissionType
                      : ""}
                  </div>
                </div>

                <div className="col-md-3">
                  <InputTextField
                    id="email"
                    label={"Number of Batch"}
                    className={"form-control-user"}
                    name="printCodes"
                    value={printCodes}
                    onChange={onChange}
                    maxLength={30}
                    placeholder={"Enter Unit Count "}
                    errorMessage={
                      errors && errors.printCodes ? errors.printCodes : ""
                    }
                  />
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label className={`form-control-label`}>Export Type</label>
                    <ReactMultiSelectCheckboxes
                      placeholder={"--Select--"}
                      placeholderButtonLabel="--Select--"
                      styles={customStyles}
                      maxMenuHeight={200}
                      options={exportTypeOptions}
                      isMulti={true}
                      noOptionsMessage={() =>
                        "No area found related to your search"
                      }
                      value={exportType}
                      onChange={(selectedOption) => {
                        onSelect(selectedOption, "exportType");
                      }}
                    />
                    <div className={"text-danger error-text"}>
                      {errors && errors.subModules ? errors.subModules : ""}
                    </div>
                  </div>
                </div>

                <div className="col-md-3" style={{ marginTop: "15px" }}>
                  <label className={`form-control-label`}>
                    Can have domain?
                  </label>
                  <AsyncSelect
                    loadOptions={loadDomain}
                    placeholder={"--Select--"}
                    styles={customStyles}
                    maxMenuHeight={200}
                    defaultOptions={domainOptions}
                    value={domainName}
                    onChange={(selectedOption) =>
                      onSelect(selectedOption, "domainName")
                    }
                    noOptionsMessage={() =>
                      "No option found related to your search"
                    }
                  />
                  <div className={"text-danger"}>
                    {errors && errors.domainName ? errors.domainName : ""}
                  </div>
                </div>
                <div
                  className="col-md-12 text-center"
                  style={{ marginTop: "38px" }}
                >
                  <Link
                    to={AppRoutes.PLANS_CUSTOM}
                    className="btn btn-primary cancel-btn"
                  >
                    CANCEL
                  </Link>

                  <SubmitButton
                    className={"submit-btn"}
                    text={isEditMode ? "UPDATE" : "ADD"}
                    isLoading={isAdding}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
