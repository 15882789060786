import React from "react";
import moment from "moment";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";
import hash from "../../../assets/img/hash.svg";
import paymentMethod from "../../../assets/img/paymentMethod.svg";
import cardnum from "../../../assets/img/cardnum.svg";
import cardholdername from "../../../assets/img/cardholdername.svg";
import amount from "../../../assets/img/amount.svg";
import paidon from "../../../assets/img/paidon.svg";
import paymentstatus from "../../../assets/img/paymentstatus.svg";

export const TransactionDetails = ({ transactionDetail, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Transaction Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={hash} />
                    <h5>Transaction ID</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {transactionDetail && transactionDetail.transactionId}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={paymentMethod} />
                    <h5>Payment Method</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="text-capitalize">
                      {/* <img src={debitcard} />{" "} */}
                      {transactionDetail && transactionDetail.cardType}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={cardnum} />
                    <h5>Card Number</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      **** **** ****{" "}
                      {transactionDetail && transactionDetail.cardNo}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={cardholdername} />
                    <h5>Cardholder’s Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {transactionDetail && transactionDetail.cardHolderName}
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={amount} />
                    <h5>Amount </h5>
                  </div>
                  <div className="description-col">
                    <h5>${transactionDetail && transactionDetail.amount}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={paidon} />
                    <h5>Paid On</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {moment
                        .utc(
                          transactionDetail && transactionDetail.transactionDate
                        )
                        .format("DD/MM/YYYY | HH:mm")}
                      Hrs
                    </h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={paymentstatus} />
                    <h5>Payment Status</h5>
                  </div>
                  <div className="description-col">
                    <h5>{transactionDetail && transactionDetail.status}</h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
