import React from "react";
import { CounterfeitIdRow } from "./CounterfeitIdRow";
import { TableLoader } from "../loader/Resusable/Table";
import noData from "../../../assets/img/no-data.png";

export const ListComponent = ({
  scans,
  isFilterApplied,
  isLoading,
  batchViewHandler,
  userViewHandler,
  setFilter,
  sortBy,
  order,
  filtersOption,
}) => {
  return (
    <div className="card-body card-table">
      <div className="table-responsive fixed-height-table-10-xs">
        {isLoading ? (
          <TableLoader columns={6} rows={10} />
        ) : (
          <table width="100%" cellSpacing="0" className="alert_table">
            <thead className="pad-top">
              {/* <tr>
                <th>
                  Scan ID{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "scan_id"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("scan_id", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "scan_id"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("scan_id", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>{" "}
                </th>
                <th>
                  Batch Number{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "batch_number"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("batch_number", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "batch_number"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("batch_number", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Product Name{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "product_name"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("product_name", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "batch_number"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("product_name", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  User Name{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "user_name"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("user_name", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "batch_number"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("user_name", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Location{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "location"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("location", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "location"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("location", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
                <th>
                  Scan Time{" "}
                  <span className={"cursor-pointer"}>
                    <span
                      className={`th-up ${
                        order === "asc" && sortBy === "created_at"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("created_at", "asc")}
                    >
                      <i className="fa fa-long-arrow-up"></i>
                    </span>
                    <span
                      className={`th-down ${
                        order === "desc" && sortBy === "created_at"
                          ? "active-ac"
                          : ""
                      }`}
                      onClick={() => setFilter("created_at", "desc")}
                    >
                      <i className="fa fa-long-arrow-down"></i>
                    </span>
                  </span>
                </th>
              </tr> */}

              <tr>
                {filtersOption.map(({ label, value }) => (
                  <th key={value}>
                    {label}{" "}
                    <span className={"cursor-pointer"}>
                      <span
                        className={`th-up ${
                          order === "asc" && sortBy === value ? "active-ac" : ""
                        }`}
                        onClick={() => setFilter(value, "asc")}
                      >
                        <i className="fa fa-long-arrow-up"></i>
                      </span>
                      <span
                        className={`th-down ${
                          order === "desc" && sortBy === value
                            ? "active-ac"
                            : ""
                        }`}
                        onClick={() => setFilter(value, "desc")}
                      >
                        <i className="fa fa-long-arrow-down"></i>
                      </span>
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {scans && scans.length ? (
                scans.map((scan, index) => (
                  <CounterfeitIdRow
                    key={index}
                    scanData={scan}
                    batchViewHandler={() =>
                      batchViewHandler(scan.batch ? scan.batch._id : "")
                    }
                    userViewHandler={() =>
                      userViewHandler(scan.user ? scan.user._id : "")
                    }
                  />
                ))
              ) : (
                <tr className="no-tr">
                  <td colSpan={5} className={"text-center image-no-data-xs"}>
                    {isFilterApplied ? (
                      <img src={noData} alt="" />
                    ) : (
                      <img src={noData} alt="" />
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
