import React, { useEffect, useState } from "react";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";
import monthlyprice from "../../../assets/img/monthly-price.svg";
import remarks from "../../../assets/img/remarks.svg";
import tag from "../../../assets/img/staffnum.svg";
import planname from "../../../assets/img/plan_name.svg";
import brief from "../../../assets/img/brief.svg";
import { ApiRoutes, AppRoutes } from "../../../config";
import { useHistory } from "react-router-dom";
import SweetAlertComponent from "../../components/sweetAlertComponent";
import { ApiHelper } from "../../../helpers";
import { toast } from "react-toastify";
import { Dropdown } from "react-bootstrap";
import filterarrowIcon from "../../../assets/img/filter-arrow.svg";

export const HeaderDetails = ({ plan, isLoading }) => {
  const [loading, setLoading] = useState();
  const [subscriptionPlan, setSubscriptionPlan] = useState();

  // eslint-disable-next-line no-undef
  useEffect(() => {
    if (isLoading != undefined) {
      setLoading(isLoading);
    }
    if (plan) {
      setSubscriptionPlan(plan);
    }
  }, [isLoading, plan]);
  const history = useHistory();

  const onDelete = async (id) => {
    const { value } = await SweetAlertComponent({
      text: `Are you sure, you want to delete this plan ?`,
    });
    if (!value) {
      return;
    }
    setLoading(true);
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.DELETE_SUBSCRIPTION.service,
      ApiRoutes.DELETE_SUBSCRIPTION.url.replace(":id", id),
      ApiRoutes.DELETE_SUBSCRIPTION.method,
      ApiRoutes.DELETE_SUBSCRIPTION.authenticate,
      undefined
    );
    setLoading(false);
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      toast.success(response?.data?.message);
      history.push(AppRoutes.PLANS_CUSTOM);
    }
  };

  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {loading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between ">
              <div className="row">
                <div className="col-md-12 d-flex align-items-center justify-content-between">
                  <h6 className="font-book  m-0">Header Details</h6>
                  <Dropdown className="no-arrow dp-right drp-tp  m-0">
                    <Dropdown.Toggle
                      variant="secondary"
                      id="dropdown-basic"
                      className={"filter-btn"}
                    >
                      Action <img src={filterarrowIcon} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="shadow dropdown-menu-right dropdown-staff m-0">
                      <Dropdown.Item
                        as="div"
                        onClick={() => {
                          history.push(
                            AppRoutes.Edit_custom_plan.replace(":id", plan.id)
                          );
                        }}
                      >
                        Edit
                      </Dropdown.Item>

                      <Dropdown.Item
                        as="div"
                        onClick={() => {
                          onDelete(plan.id);
                        }}
                      >
                        Delete
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-4">
                  <div className="heading-col">
                    <img src={monthlyprice} alt="" />
                    <h5>Monthly Price</h5>
                  </div>
                  <div className="description-col">
                    <h5 className="">${plan.price}</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="heading-col">
                    <img src={tag} alt="" />
                    <h5>Client</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan?.clientId?.first_name}</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="heading-col">
                    <img src={remarks} alt="" />
                    <h5>Remarks</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.remark}</h5>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="heading-col">
                    <img src={planname} alt="" />
                    <h5>Plan Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.name}</h5>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="heading-col">
                    <img src={brief} alt="" />
                    <h5>Brief</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.description}</h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
