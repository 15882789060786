import React, { Component, useState } from "react";
import moment from "moment";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "../../../config";
import listIcon from "../../../assets/img/list.svg";
import Modal from "react-bootstrap/Modal";
import userIcon from "../../../../src/assets/img/user-4.svg";
import scanid from "../../../../src/assets/img/scanid.svg";
import hashtag from "../../../../src/assets/img/hash.svg";
import star from "../../../../src/assets/img/star.svg";
import purchace_status from "../../../../src/assets/img/purchace_status.svg";
import { ApiHelper } from "../../../helpers";
import { ApiRoutes } from "../../../config";

export const AlertRow = ({ scanData, batchViewHandler }) => {
  const { batch = {}, product = {}, user = {}, updatedAt } = scanData;
  const { batchNumber = "" } = batch || {};
  const { productName = "" } = product || {};
  const { first_name = "", other_info = {} } = user || {};

  const [show, setShow] = useState(false);
  const [alertDetails, setAlertDetails] = useState([]);
  const [scan, setScan] = useState();
  const handleClose = () => setShow(false);
  const handleShow = async (id) => {
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_Alert_DETAILS.service,
      ApiRoutes.GET_Alert_DETAILS.url.replace(":id", id),
      ApiRoutes.GET_Alert_DETAILS.method,
      ApiRoutes.GET_Alert_DETAILS.authenticate,
      undefined,
      undefined
    );
    if (response && response.isError) {
      // this.props.history.push(AppRoutes.MANAGE_USER);
    } else {
      const { data } = response.data;
      setAlertDetails(data?.scans);
    }
    setShow(true);
  };

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  return (
    <>
      <tr>
        <td style={{ color: "#0A1F44" }}>{scanData.batchUnit.codeId}</td>
        <td
          className={"cursor-pointer"}
          onClick={batchViewHandler ? batchViewHandler : null}
        >
          {batchNumber}
        </td>
        <td className="pname_scan">{scanData.scanCount}</td>
        {scanData.scanRule.alertType == "Mild" ? (
          <td
            className={"cursor-pointer severity_td"}
            // onClick={userViewHandler ? userViewHandler : null}
          >
            <p className="severity_status mild">Mild</p>
          </td>
        ) : (
          <td
            className={"cursor-pointer severity_td"}
            // onClick={userViewHandler ? userViewHandler : null}
          >
            <p className="severity_status potential">
              {scanData.scanRule.alertType}
            </p>
          </td>
        )}
        <td className="ploc_scan">
          {updatedAt
            ? [
                moment(updatedAt).format(DEFAULT_TIME_FORMAT),
                moment(updatedAt).format(DEFAULT_DATE_FORMAT),
              ].join(" Hrs | ")
            : ""}
        </td>
        <td
          className={"cursor-pointer"}
          // onClick={batchViewHandler ? batchViewHandler : null}
        >
          <button
            className="btn btn-secondary th-btn"
            // onClick={handleShow}
            onClick={() => handleShow(scanData.batchUnitId)}
          >
            {" "}
            <img src={listIcon} alt="" /> View{" "}
          </button>
        </td>
      </tr>
      <Modal className="view_alert_modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={hashtag} className="" alt="" />
                  Code ID:
                </label>
                <span>{scanData?.batchUnit?.codeId}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Batch Number:
                </label>
                <span>{scanData?.batch?.batchNumber}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={hashtag} className="" alt="" />
                  Product Name:
                </label>
                <span>{scanData?.product?.productName}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Scan count:
                </label>
                <span>{scanData.scanCount}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={hashtag} className="" alt="" />
                  Triggered On:
                </label>
                <span>
                  {updatedAt
                    ? [
                        moment(updatedAt).format(DEFAULT_TIME_FORMAT),
                        moment(updatedAt).format(DEFAULT_DATE_FORMAT),
                      ].join(" Hrs | ")
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={purchace_status} className="" alt="" />
                  Severity:
                </label>
                {scanData.scanRule.alertType == "Mild" ? (
                  <span className="status mild ">Mild</span>
                ) : (
                  <span className="status potential">
                    {scanData.scanRule.alertType}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table_wrapper">
                <table>
                  <tr>
                    <th>Scan ID</th>
                    <th>User Name</th>
                    <th>Location</th>
                    <th>Scan Date & Time</th>
                  </tr>
                  {alertDetails?.map((item) => {
                    return (
                      <tr>
                        <td>{item.scanId}</td>
                        <td>
                          {item.user?.first_name} {item.user?.last_name}{" "}
                          <span
                            onClick={() => {
                              if (item.locs) {
                                const a = [...item.locs];
                                item.rev = a.reverse();
                              }
                              setScan(item);
                              handleShow2();
                            }}
                          >
                            {item.loc ? item.loc.length : item.locs.length}{" "}
                            scans
                          </span>
                        </td>
                        <td>
                          <div className="" style={{ whiteSpace: "pre-line" }}>
                            {item.loc
                              ? item.user.address1
                              : item.locs[item.locs.length - 1].address}
                          </div>
                        </td>
                        <td>
                          {item.locs[item.locs.length - 1].date
                            ? [
                                moment(
                                  item.locs[item.locs.length - 1].date
                                ).format(DEFAULT_TIME_FORMAT),
                                moment(
                                  item.locs[item.locs.length - 1].date
                                ).format(DEFAULT_DATE_FORMAT),
                              ].join(" Hrs | ")
                            : ""}
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal className="view_alert_modal" show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={userIcon} className="" alt="" />
                  User name
                </label>
                <span>
                  {" "}
                  {scan?.user?.first_name} {scan?.user?.last_name}
                </span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Scan ID
                </label>
                <span>{scan?.scanId}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={hashtag} className="" alt="" />
                  Batch Number
                </label>
                <span>{scan?.batch?.batchNumber}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={scanid} className="" alt="" />
                  Code ID
                </label>
                <span>{scan?.batchUnit?.codeId}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={star} className="" alt="" />
                  Product Name
                </label>
                <span>{scan?.product?.productName}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group d-flex flex-column">
                <label htmlFor="first_name" className="form-control-label">
                  <img src={purchace_status} className="" alt="" />
                  Purchase Status
                </label>
                <span className="">
                  {scan?.batchUnit?.isPurchased ? "Yes" : "No"}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table_wrapper">
                <table>
                  <tr>
                    <th>Location</th>
                    <th>Scan Date & Time</th>
                  </tr>
                  {scan?.loc ? (
                    <>
                      <tr>
                        <td style={{ width: "70%" }}>
                          <div style={{ whiteSpace: "pre-line" }}>
                            {scan?.user?.address1}
                          </div>
                        </td>
                        <td style={{ width: "30%" }}>
                          <div style={{ whiteSpace: "pre-line" }}>
                            {scan?.createdAt
                              ? [
                                  moment(scan?.createdAt).format(
                                    DEFAULT_TIME_FORMAT
                                  ),
                                  moment(scan?.createdAt).format(
                                    DEFAULT_DATE_FORMAT
                                  ),
                                ].join(" Hrs | ")
                              : ""}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    scan?.rev?.map((item) => {
                      return (
                        <>
                          <tr>
                            <td style={{ width: "70%" }}>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {item.address}
                              </div>
                            </td>
                            <td style={{ width: "30%" }}>
                              <div style={{ whiteSpace: "pre-line" }}>
                                {item.date
                                  ? [
                                      moment(item.date).format(
                                        DEFAULT_TIME_FORMAT
                                      ),
                                      moment(item.date).format(
                                        DEFAULT_DATE_FORMAT
                                      ),
                                    ].join(" Hrs | ")
                                  : ""}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </table>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
