import React from "react";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";
import companyname from "../../../assets/img/star.svg";
import clientid from "../../../assets/img/clientid.svg";
import email from "../../../assets/img/email.svg";
import phone from "../../../assets/img/phone.svg";

export const ClientDetails = ({ clientDetails, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between ">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Client Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={companyname} />
                    <h5>Company Name</h5>
                  </div>
                  <div className="description-col">
                    <h5>{clientDetails && clientDetails.companyName}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={clientid} />
                    <h5>Client ID</h5>
                  </div>
                  <div className="description-col">
                    <h5>{(clientDetails && clientDetails.clientId) || "-"}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={email} />
                    <h5>Email</h5>
                  </div>
                  <div className="description-col">
                    <h5>{clientDetails && clientDetails.email}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={phone} />
                    <h5>Phone Number</h5>
                  </div>
                  <div className="description-col">
                    <h5>
                      {(clientDetails && clientDetails.phoneNumber) || "-"}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
