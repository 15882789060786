import React from "react";
import { CounterfeitProduct } from "../loader/Resusable/CounterfeitProduct";
import staffnum from "../../../assets/img/staffnum.svg";
import permissiontype from "../../../assets/img/permissiontype.svg";
import batchnum from "../../../assets/img/batchnum.svg";
import exporttype from "../../../assets/img/exporttype.svg";
import domain from "../../../assets/img/domain.svg";

export const DynamicDetails = ({ plan, isLoading }) => {
  return (
    <>
      <div className="card card-main flex-fill clientDetailsCard">
        {isLoading ? (
          <CounterfeitProduct />
        ) : (
          <>
            <div className="card-header  py-7550  align-items-center justify-content-between ">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="font-book ">Dynamic Details</h6>
                </div>
              </div>
            </div>
            <div className="card-body card-table">
              <div className="row mtblf-5">
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={staffnum} alt="" />
                    <h5>Number of Staff</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.users}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={permissiontype} alt="" />
                    <h5>Permission Type</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.permission_type}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={batchnum} alt="" />
                    <h5>Number of Batch</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.printCodes}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={exporttype} alt="" />
                    <h5>Export Type</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.export_type?.toString()}</h5>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="heading-col">
                    <img src={domain} alt="" />
                    <h5>Can have Domain</h5>
                  </div>
                  <div className="description-col">
                    <h5>{plan.domainName ? "Yes" : "No"}</h5>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};
