import React from "react";
import { TableLoader } from "../loader/Resusable/Table";
import editIcon from "../../../assets/img/edit-3.svg";
import trashIcon from "../../../assets/img/trash-2.svg";
import trashIconWhite from "../../../assets/img/trash-2-white.svg";
import editIconWhite from "../../../assets/img/edit-3-white.svg";
import listIcon from "../../../assets/img/list.svg";
import Switch from "react-switch";
import { Button } from "react-bootstrap";

export const PlanListComponent = ({
  onEdit,
  subscription,
  setFilter,
  sortBy,
  order,
  isLoading,
  onDelete,
  viewPlan,
  activeToggle,
}) => {
  return (
    <>
      <div className="card-body card-table">
        <div className="table-responsive fixed-height-table-10-lg">
          {isLoading ? (
            <TableLoader columns={5} rows={10} />
          ) : (
            <table className="plansTable" width="100%" cellSpacing="0">
              <thead className="pad-top">
                <tr>
                  {[
                    { label: "Plan Name", value: "name" },
                    { label: "Remark", value: "remark" },
                    { label: "Price", value: "price" },
                    { label: "Priority", value: "priority" },
                    { label: "Status", value: "isActive" },
                  ].map(({ label, value }) => (
                    <th key={value}>
                      {label}{" "}
                      <span className={"cursor-pointer"}>
                        <span
                          className={`th-up ${
                            order === "asc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "asc")}
                        >
                          <i className="fa fa-long-arrow-up"></i>
                        </span>
                        <span
                          className={`th-down ${
                            order === "desc" && sortBy === value
                              ? "active-ac"
                              : ""
                          }`}
                          onClick={() => setFilter(value, "desc")}
                        >
                          <i className="fa fa-long-arrow-down"></i>
                        </span>
                      </span>
                    </th>
                  ))}
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {subscription &&
                  subscription.map((info, index) => (
                    <tr key={index}>
                      <td>{info.name}</td>
                      <td>{info.remark || "N/A"}</td>
                      <td>${info.price}</td>
                      <td>{info.priority}</td>
                      <td>
                        <div className="switch_box">
                          <Switch
                            onChange={(item) => {
                              activeToggle(index);
                            }}
                            checked={info.isActive}
                            onColor="#0F51A1"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            width={48}
                            height={28}
                          />
                          <p>{info.isActive ? "Enabled" : "Disabled"}</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          <button
                            className="btn btn-secondary th-btn"
                            onClick={() => viewPlan(info)}
                          >
                            {" "}
                            <img src={listIcon} alt="" /> View{" "}
                          </button>
                          <Button
                            className="btn btn-secondary th-btn edit-tb action-block-btn"
                            onClick={() => onEdit(info)}
                          >
                            <span>
                              <img
                                src={editIcon}
                                alt=""
                                className={"img-main"}
                              />
                              <img
                                src={editIconWhite}
                                alt=""
                                className={"img-hover"}
                              />
                            </span>
                            Edit
                          </Button>
                          <Button
                            variant="danger"
                            className="btn th-btn delete-tb action-block-btn"
                            onClick={() => onDelete(info.id)}
                          >
                            <span>
                              <img
                                src={trashIcon}
                                alt=""
                                className={"img-main"}
                              />
                              <img
                                src={trashIconWhite}
                                alt=""
                                className={"img-hover"}
                              />
                            </span>
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {/* {totalRecords > limit ? (
          <div className='float-right mt-2'>
          <Pagination
              totalRecords={totalRecords}
              currentPage={currentPage}
              pageLimit={limit}
              onPageChanged={onPageChanged}
           /> 
          </div>
        ) : null}*/}
        </div>
      </div>
    </>
  );
};
