import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "../../../components/partial/Breadcrumbs";
import Pagination from "../../../components/pagination";
import { PlanListComponent } from "../../../components/PlanListComponent/PlanListComponent.jsx";
import { ApiRoutes, AppRoutes } from "../../../../config";
import { ApiHelper } from "../../../../helpers";
import { toast } from "react-toastify";
import { addJob } from "../../../../validators/addJob";
import * as qs from "query-string";
import { AddJobFormComponent } from "../../../components/careers/addJobFormComponent.jsx";
import "./addJob.css";
import { getCountries, getStates } from "country-state-picker";

class AddJobPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planData: [],
      countryList: [],
      isLoading: false,
      totalRecords: 0,
      currentPage: 1,
      limit: 10,
      deleteError: false,
      sortBy: "createdAt",
      order: "desc",
      planType: "",
      planDuration: "",
      name: "",
      description: "",
      price: "",
      users: "",
      BrandAndCertificate: null,
      printCodes: "",
      domainName: null,
      email: "",
      errors: null,
      isEditMode: false,
      planId: "",
      clientList: [],
      clientId: "",
      clientName: "",
      country: "",
      state: "",
      stateList: [],
      jobId: "",
      jobTitle: "",
      jobType: "",
      jobCategory: "",
      linkedin: "",
      address: "",
      basic: "",
      jobDescription: "",
      preQualifications: "",
      additonalHeading: "",
      additonalContent: "",
    };
  }

  componentDidMount = () => {
    const {
      match: {
        params: { id },
      },
    } = this.props;
    if (id) {
      this.setState({ planId: id, isEditMode: true }, () => {
        this.getPlansById();
      });
    }
    this.fetchClientList();
  };

  addJob = async (payload) => {
    this.setState({
      isAdding: true,
    });
    if (payload.linkedin == null || payload.linkedin == "") {
      delete payload.linkedin;
    }
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.ADD_JOB.service,
      ApiRoutes.ADD_JOB.url,
      ApiRoutes.ADD_JOB.method,
      ApiRoutes.ADD_JOB.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push("/cms/Web/careers/");
    }
  };

  fetchClientList = async () => {
    const response = await new ApiHelper().NewFetchFromServer(
      ApiRoutes.GET_JOB_TITLES.service,
      ApiRoutes.GET_JOB_TITLES.url,
      ApiRoutes.GET_JOB_TITLES.method,
      ApiRoutes.GET_JOB_TITLES.authenticate,
      { order: "ASC" },
      undefined,
      undefined,
      undefined
    );
    const countryList = getCountries();
    this.setState({ countryList: countryList });
    if (response && response.isError) {
    } else {
      const { data } = response.data;
      this.setState({
        clientList: response.data.data.jobTitles,
      });
    }
  };

  getPlansById = async () => {
    const { planId, clientName } = this.state;

    this.setState({
      isLoading: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.GET_JOB_TITLES.service,
      ApiRoutes.GET_JOB_TITLES.url.replace(":id", planId),
      ApiRoutes.GET_JOB_TITLES.method,
      ApiRoutes.GET_JOB_TITLES.authenticate,
      // data,
      undefined
    );
    this.setState({
      isLoading: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
    } else {
      const { data: planData } = response.data;
      const {
        planType,
        name,
        description,
        price,
        users,
        BrandAndCertificate,
        printCodes,
        domainName,
        email,
        clientId,
        clientName,
      } = planData;
      this.setState({
        planType:
          {
            label: planType,
            value: planType,
          } || "",
        name: name || "",
        description: description || "",
        price: price ? price : "" || "",
        users: users ? parseInt(users) : "" || "",
        BrandAndCertificate:
          {
            label: BrandAndCertificate ? "Yes" : "No",
            value: BrandAndCertificate ? 1 : 2,
          } || "",
        printCodes: printCodes ? parseInt(printCodes) : "" || "",
        domainName:
          {
            label: domainName ? "Yes" : "No",
            value: domainName ? 1 : 2,
          } || "",
        email: email || "",
        clientId: { label: clientId?.first_name, value: clientId?.id },
      });
    }
  };

  updatePlan = async (payload) => {
    const { planId } = this.state;

    this.setState({
      isAdding: true,
    });
    const response = await new ApiHelper().FetchFromServer(
      ApiRoutes.EDIT_SUBSCRIPTION.service,
      ApiRoutes.EDIT_SUBSCRIPTION.url.replace(":id", planId),
      ApiRoutes.EDIT_SUBSCRIPTION.method,
      ApiRoutes.EDIT_SUBSCRIPTION.authenticate,
      undefined,
      payload
    );
    this.setState({
      isAdding: false,
    });
    if (response && response.isError) {
      toast.error(response.messages[0]);
      this.setState({
        errors: response.validationErrors,
      });
    } else {
      toast.success(response.data.message);
      this.props.history.push(AppRoutes.EDITJOB_CAREERS);
    }
  };

  handleSubmit = async (event) => {
    event && event.preventDefault();
    const {
      jobId,
      country,
      address,
      linkedin,
      state,
      jobDescription,
      preQualifications,
      basic,
      additonalHeading,
      additonalContent,
    } = this.state;

    let payload = {
      jobTitleId: jobId.value,
      country: country.value,
      city: state.value,
      address: address,
      linkedin: linkedin,
      jobDescription: jobDescription,
      basicQualifications: basic,
      preferredQualifications: preQualifications,
      additionalInfoTitle: additonalHeading,
      additionalInfoDescription: additonalContent,
    };

    const { isValid, errors } = addJob(payload);
    if (isValid) {
      this.addJob({
        jobTitleId: jobId.value,
        country: country.value,
        city: state.value,
        linkedin: linkedin,
        address: address,
        jobDescription: jobDescription,
        basicQualifications: basic,
        preferredQualifications: preQualifications,
        ...(additonalHeading == ""
          ? {}
          : { additionalInfoTitle: additonalHeading }),
        ...(additonalContent == ""
          ? {}
          : { additionalInfoDescription: additonalContent }),
      });
    } else {
      this.setState({ errors: errors });
      return;
    }
  };
  onSelect = (label, name) => {
    if (label) {
      if (name == "jobId" || name == "jobTitle") {
        const jobTitle = this.state.clientList.find(
          (i) => i._id == label.value
        );
        if (jobTitle) {
          this.setState({
            jobTitle: { label: jobTitle.jobTitle, value: jobTitle._id },
            jobId: { label: jobTitle.jobId, value: jobTitle._id },
            jobType: jobTitle.jobType,
            jobCategory: jobTitle.jobCategory,
          });
        }
      }
      this.setState({
        [name]: label,
      });
      if (name == "country") {
        this.getStateByCountry(label.value);
      }
      const {
        jobId,
        country,
        address,
        linkedin,
        state,
        jobDescription,
        preQualifications,
        basic,
        additonalHeading,
        additonalContent,
      } = this.state;

      let payload = {
        jobTitleId: name == "jobId" ? label.label : jobId.value,
        country: name == "country" ? label.value : country.value,
        city: name == "state" ? label.value : state.value,
        address: address,
        linkedin: linkedin,
        jobDescription: jobDescription,
        basicQualifications: basic,
        preferredQualifications: preQualifications,
        additionalInfoTitle: additonalHeading,
        additionalInfoDescription: additonalContent,
      };
      const { isValid, errors } = addJob(payload);
      if (isValid) {
      } else {
        this.setState({ errors: errors });
        return;
      }
    }
  };

  handleChange = async (event) => {
    const {
      target: { name, value },
    } = event;

    if (name === "linkedin") {
      this.setState({ linkedin: value });
    }
    if (name === "country") {
      this.setState({ country: value.trimLeft() });
      this.getStateByCountry(value);
    }
    if (name === "state") {
      this.setState({ state: value.trimLeft() });
    }
    if (name === "address") {
      this.setState({ address: value });
    }
    if (name === "basic") {
      this.setState({ basic: value });
    }
    if (name === "jobDescription") {
      this.setState({ jobDescription: value });
    }
    if (name === "preQualifications") {
      this.setState({ preQualifications: value });
    }
    if (name === "additonalContent") {
      this.setState({ additonalContent: value });
    }
    if (name === "additonalHeading") {
      this.setState({ additonalHeading: value });
    }
    const {
      jobId,
      country,
      address,
      linkedin,
      state,
      jobDescription,
      preQualifications,
      basic,
      additonalHeading,
      additonalContent,
    } = this.state;

    let payload = {
      jobTitleId: jobId.value,
      country: country.value,
      city: state.value,
      address: name == "address" ? value : address,
      linkedin: name == "linkedin" ? value : linkedin,
      jobDescription: name == "address" ? value : jobDescription,
      basicQualifications: name == "basicQualifications" ? value : basic,
      preferredQualifications:
        name == "preferredQualifications" ? value : preQualifications,
      additionalInfoTitle:
        name == "additionalInfoTitle" ? value : additonalHeading,
      additionalInfoDescription:
        name == "additionalInfoDescription" ? value : additonalContent,
    };
    const { isValid, errors } = addJob(payload);
    if (isValid) {
    } else {
      this.setState({ errors: errors });
      return;
    }
  };

  getStateByCountry = (value) => {
    const countryList = getCountries();
    const selectedCountry = countryList?.filter(
      (country) => country?.name === value
    );
    const data = getStates(selectedCountry?.[0]?.code);
    this.setState({ stateList: data });
  };

  render() {
    const {
      errors,
      name,
      price,
      email,
      description,
      users,
      printCodes,
      domainName,
      BrandAndCertificate,
      planType,
      isAdding,
      isLoading,
      isEditMode,
      clientList,
      clientId,
      countryList,
      stateList,
      state,
      country,
      jobId,
      jobTitle,
      jobType,
      jobCategory,
      linkedin,
      address,
      basic,
      jobDescription,
      preQualifications,
      additonalHeading,
      additonalContent,
      jobTitleId,
    } = this.state;

    const {
      props: {
        location: { pathname },
      },
    } = this;
    return (
      <>
        <div className="container-fluid fluid-pd-2">
          <h1 className="h3-head-mob d-md-none">
            {isEditMode ? "Edit Job Listing" : "Add Job Listing"}
          </h1>
          <Breadcrumbs pathname={pathname} />
          <div className="card card-main card-pagination addplanCard">
            <div className="card-header  py-7550  align-items-center justify-content-between">
              <div className="row">
                <div className="col-md-3 head-cat-col-lg">
                  <h6 className="font-book">
                    {isEditMode ? "Edit Job Listing" : "Add Job Listing"}
                  </h6>
                </div>
              </div>
            </div>
            <AddJobFormComponent
              isLoading={isLoading}
              handleSubmit={this.handleSubmit}
              isEditMode={isEditMode}
              name={name}
              price={price}
              email={email}
              description={description}
              domainName={domainName}
              BrandAndCertificate={BrandAndCertificate}
              users={users}
              planType={planType}
              printCodes={printCodes}
              onSelect={this.onSelect}
              errors={errors}
              onChange={this.handleChange}
              isAdding={isAdding}
              clientList={clientList}
              clientId={clientId}
              countryList={countryList}
              stateList={stateList}
              state={state}
              country={country}
              jobId={jobId}
              jobTitle={jobTitle}
              jobType={jobType}
              jobCategory={jobCategory}
              linkedin={linkedin}
              address={address}
              basic={basic}
              jobDescription={jobDescription}
              preQualifications={preQualifications}
              additonalHeading={additonalHeading}
              additonalContent={additonalContent}
            />
          </div>
        </div>
      </>
    );
  }
}

export default AddJobPage;
