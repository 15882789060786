import Validator from "js-object-validation";
import { message } from "../common";

export const addContactPlan = (data) => {
  const validations = {
    name: {
      required: true,
    },
    description: {
      required: true,
    },
    email: {
      required: true,
    },
    priority: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    priority: {
      required: message.Required.replace(":item", "priority"),
    },
    name: {
      required: message.Required.replace(":item", "name"),
      // alpha: message.InvalidName.replace(":item", "name"),
    },
    email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    description: {
      required: message.Required.replace(":item", "description"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
