import React from "react";
import AsyncSelect from "react-select/async";
import { InputTextField, SubmitButton } from "../forms";
import "./user.css";
import countryStateData from "../../../common/countryState.json";
import { ApiHelper } from "../../../helpers";
import { components } from "react-select";
import { areaCode } from "../../../common/phoneCode";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "0px solid #fff",
    color: state.isSelected ? "#8a94a6" : "#8a94a6",
    background: "#fff",
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),

  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: "#fff",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Medium",
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    fontFamily: "CircularStd-Book",
  }),
};

export const AddScanRulesComponent = ({
  name,
  noOfUsers,
  sendAlert,
  alertType,
  errors,
  isAdding,
  onChange,
  onSelect,
  handleCancel,
  handleSubmit,
  onSelectOption,
  // loadOriginOptions,
  country,
}) => {
  let allCountry = [];
  countryStateData.forEach((element) => {
    allCountry.push({ label: element.name, value: element.name });
  });

  const AlertTypeArray = [
    {
      label: "Mild",
      value: "Mild",
    },
    {
      label: "Potential",
      value: "Potential",
    },
  ];
  const sendAlertOptions = [
    {
      label: "App Users",
      value: "App Users",
    },
    {
      label: "Admin",
      value: "Admin",
    },
    {
      label: "Both",
      value: "Both",
    },
  ];

  const loadOriginOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = allCountry.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };

  const loadOriginAlertOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = AlertTypeArray.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };

  const loadOriginSendAlertOptions = async (inputValue, callback) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = sendAlertOptions.filter(
        (name) =>
          name.label.toLowerCase().search(inputValue.toLowerCase()) !== -1
      );
      return resolve(result);
    });
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <div className="d-none" />
      </components.DropdownIndicator>
    );
  };
  const customStylesAsync = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "0px solid #fff",
      color: state.isSelected ? "#8a94a6" : "#8a94a6",
      background: "#fff",
      fontSize: "14px",
      fontFamily: "Poppins",
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    noOptionsMessage: (provided, state) => ({
      ...provided,
      fontSize: "14px",
      fontFamily: "Poppins",
    }),
    control: (provided, state) => ({
      ...provided,
      "&:hover": {
        borderColor: "unset",
        border: "0px",
      },
      border: "0px",
      padding: "0px",
      height: "100%",
      backgroundColor: "transparent",
      boxShadow: "unset",
      minWidth: "50px",
      // display: "block",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      margin: "0px",
      maxWidth: "65px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
    }),
    container: (provided, state) => ({
      ...provided,
      padding: "0px",
      backgroundColor: "transparent",
    }),
    menu: (provided, state) => ({
      ...provided,
      minWidth: "100px",
    }),
  };

  let codeData = [];
  areaCode.forEach((element) => {
    codeData.push({ label: element.dial_code, value: element.dial_code });
  });
  const loadAreaCodeData = async (inputValue) => {
    new ApiHelper().cancelRequest("cancel");
    let result;
    return await new Promise(async (resolve, reject) => {
      result = codeData.filter((code) =>
        code.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      return resolve(result);
    });
  };

  return (
    <form className="user_add add_scan_rules" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-3 mob-show">
          {/* <div className='img-col-user'>
            <img
              className='user-img'
              src={
                profile_picture
                  ? `${AppConfig.FILES_ENDPOINT}${profile_picture}`
                  : uploadImage
              }
              alt=''
            />
          </div>
          {isUploading ? (
            <ProgressBar now={uploadPercentage} className={'mt-2'} />
          ) : null} */}
          {/* <div className='file-upload-btn'>
            <label className='file-label'>
              {' '}
              {profile_picture ? 'Change Photo' : 'Choose Photo'}
              <input
                type='file'
                name='profile_picture'
                accept='image/x-png,image/jpeg ,image/jpg, image/png ,image/svg'
                onChange={onFileChange}
              />
            </label>
            <span onClick={onDeleteFile} className={'cursor-pointer'}>
              <img src={trashIcon} alt='' />
            </span>
          </div> */}
        </div>
        <div className="col-md-12">
          <div className="row" style={{ marginTop: "20px" }}>
            <div className="col-md-4">
              <InputTextField
                id="name"
                label={"Rule Name"}
                className={"form-control-user"}
                name="name"
                value={name}
                onChange={onChange}
                errorMessage={errors && errors.name ? errors.name : ""}
                maxLength={30}
                placeholder="Enter Rule Name here"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <h4 className="form_title font-book">Parameters</h4>
            </div>
            <div className="col-md-4">
              <InputTextField
                id="noOfUsers"
                label={"Number of Users"}
                className={"form-control-user"}
                name="noOfUsers"
                value={noOfUsers}
                onChange={onChange}
                errorMessage={
                  errors && errors.noOfUsers ? errors.noOfUsers : ""
                }
                maxLength={30}
                placeholder="Enter Number of users"
              />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Country</label>
                <AsyncSelect
                  loadOptions={loadOriginOptions}
                  placeholder={"Country"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={allCountry}
                  value={country}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "country")
                  }
                  noOptionsMessage={() =>
                    "No country found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.country ? errors.country : ""}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Alert Type</label>
                <AsyncSelect
                  loadOptions={loadOriginAlertOptions}
                  placeholder={"Alert Type"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={AlertTypeArray}
                  value={alertType}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "alertType")
                  }
                  noOptionsMessage={() =>
                    "No Alert Type found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.alertType ? errors.alertType : ""}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label className={`form-control-label`}>Send Alert to</label>
                <AsyncSelect
                  loadOptions={loadOriginSendAlertOptions}
                  placeholder={"Send Alert to"}
                  styles={customStyles}
                  maxMenuHeight={200}
                  defaultOptions={sendAlertOptions}
                  value={sendAlert}
                  onChange={(selectedOption) =>
                    onSelect(selectedOption, "sendAlert")
                  }
                  noOptionsMessage={() =>
                    "No country found related to your search"
                  }
                />
                <div className={"text-danger"}>
                  {errors && errors.sendAlert ? errors.sendAlert : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-9 footer-form text-left">
          <button
            type="button"
            className="btn btn-primary cancel-btn"
            onClick={handleCancel}
          >
            {" "}
            CANCEL
          </button>

          <SubmitButton
            className={"submit-btn"}
            text={"Add"}
            isLoading={isAdding}
            onSubmit={handleSubmit}
          />
        </div>
      </div>
    </form>
  );
};
