import Validator from "js-object-validation";
import { message } from "../common";

export const addPlan = (data) => {
  const validations = {
    name: {
      required: true,
    },
    price: {
      required: true,
      numeric: true,
    },
    description: {
      required: true,
    },
    users: {
      required: true,
    },
    printCodes: {
      required: true,
    },
    remark: {
      required: false,
    },
    tags: {
      required: false,
    },
    domainName: {
      required: true,
    },
    permission_type: {
      required: true,
    },
    export_type: {
      required: true,
    },
    priority: {
      required: true,
    },
  };
  // Error messages
  const messages = {
    planType: {
      required: message.Required.replace(":item", "plan type"),
    },
    priority: {
      required: message.Required.replace(":item", "priority"),
    },
    name: {
      required: message.Required.replace(":item", "name"),
      // alpha: message.InvalidName.replace(":item", "name"),
    },
    price: {
      required: message.Required.replace(":item", "plan price"),
      numeric: message.InvalidNumber,
    },
    email: {
      required: message.Required.replace(":item", "email"),
      email: message.InvalidEmail,
    },
    description: {
      required: message.Required.replace(":item", "description"),
    },
    users: {
      required: message.Required.replace(":item", "user count"),
    },
    printCodes: {
      required: message.Required.replace(":item", "batch print count"),
    },
    addCustomPlan: {
      required: message.Required.replace(":item", "remark"),
    },
    domainName: {
      required: message.Required.replace(":item", "option"),
    },
    clientId: {
      required: message.Required.replace(":item", "option"),
    },
    permission_type: {
      required: message.Required.replace(":item", "option"),
    },
    export_type: {
      required: message.Required.replace(":item", "option"),
    },
  };

  const { isValid, errors } = Validator(data, validations, messages);
  return {
    isValid,
    errors,
  };
};
